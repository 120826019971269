import React, { useEffect, useState } from "react";
import { useApiUrl, useList } from "@refinedev/core";
import { AutoComplete, Button, Col, List, Row, Space, Table, Typography, message } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { axiosInstance } from "@refinedev/simple-rest";

import { SubjectData } from "interfaces";
import { useTitle } from "contexts/TitleContext";

const { Text, Title } = Typography;

interface PaperLink {
    key: React.Key;
    name: string;
    link: string;
}

export const SubjectPapers: React.FC = () => {
    const apiUrl = useApiUrl();

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Download Subject Papers');
    }, []);

    const [subjects, setSubjects] = useState<SubjectData[]>([]);
    const [subject, setSubject] = useState<SubjectData | undefined>();
    const [year, setYear] = useState<string | undefined>();
    const [links, setLinks] = useState<PaperLink[]>([]);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loadingZip, setLoadingZip] = useState<boolean>(false);
    const [selectedLinks, setSelectedLinks] = useState<PaperLink[]>([]);
    const [years, setYears] = useState<string[]>(Array.from({ length: new Date().getFullYear() - 2010 + 1 }, (_, index) => (new Date().getFullYear() - index).toString()));

    const { data: subject_data } = useList<SubjectData>({
        resource: `dashboard/admin/subject-details`
    });

    const subject_list = subject_data?.data ?? [];

    useEffect(() => {
        if (subject_list) {
            setSubjects(subject_list);
        }
    }, [subject_list]);

    const columns = [
        {
            title: 'Paper Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: PaperLink) => (
                <a href={record.link} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            ),
        },
    ];

    const handleSearch = async () => {
        if (!subject || !year) {
            message.error('Please select both subject and year.');
            return;
        }

        setLinks([]);
        setSelectedLinks([]);
        setLoadingSearch(true);
        try {
            const response = await axiosInstance.get(`${apiUrl}/dashboard/admin/subject/${subject?.id}/papers/${year}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setLinks(body.map((link: any, index: any) => ({
                    key: index,
                    name: link.name,
                    link: link.link,
                })));
                setLoadingSearch(false);
            }

        } catch (error) {
            console.error(error);
            message.error('Failed to fetch papers.');
            setLoadingZip(false);
        }
    };

    const handleDownloadZip = async () => {
        if (links.length === 0) {
            message.error('No papers available to download.');
            return;
        }

        setLoadingZip(true);
        try {
            const post_data = {
                papers: selectedLinks,
                subject: subject?.name,
                year: year
            }
            const response = await axiosInstance.post(`${apiUrl}/dashboard/admin/zip`, post_data, {
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'blob'
            });
            if (response.status === 200) {
                const now = new Date();
                const formattedDate = now
                    .toISOString()
                    .replace(/[:.]/g, '-')
                    .replace('T', '_')
                    .replace('Z', '')
                    .split('.')[0];
                const fileName = `${formattedDate}_${subject?.name}_${year}.zip`;

                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);

                setLoadingZip(false);
            }

        } catch (error) {
            console.error(error);
            message.error('Failed to download papers.');
            setLoadingZip(false);
        }
    };

    const handleSubjectChange = (value: any) => {
        setSubject(subjects.find(subject => subject.id === value));
    }

    const handleYearChange = (value: any) => {
        setYear(value);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: PaperLink[]) => {
            setSelectedLinks(selectedRows);
        },
        selectedRowKeys: selectedLinks.map((link) => link.key),
    };

    return (<>
        <Row style={{ marginBottom: '24px' }} gutter={16}>
            <Col span={24}>
                <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Download Past Papers</Title>
            </Col>
        </Row>
        <Row style={{ marginBottom: '24px' }} gutter={16}>
            <Col span={24}>
                <Space direction="vertical" size="large">
                    <Space>
                        <AutoComplete
                            style={{ width: 200 }}
                            placeholder="Select subject"
                            options={subjects.map(subject => ({ value: subject.id, label: subject.name }))}
                            onChange={handleSubjectChange}
                            value={subject?.name}
                            allowClear
                            filterOption={(inputValue, option: any) =>
                                option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <AutoComplete
                            style={{ width: 200 }}
                            placeholder="Select year"
                            options={years.map(year => ({ value: year }))}
                            onChange={handleYearChange}
                            value={year}
                            allowClear
                        />
                        <Button type="primary" onClick={handleSearch} loading={loadingSearch}>
                            Search
                        </Button>
                    </Space>

                    {links.length > 0 && (
                        <>
                            <Table
                                dataSource={links}
                                columns={columns}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                pagination={false}
                                bordered
                                style={{ backgroundColor: 'white', width: '100%' }}
                            />
                            {selectedLinks.length > 0 && (
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    onClick={handleDownloadZip}
                                    style={{ width: '200px' }}
                                    loading={loadingZip}
                                    block
                                >
                                    Download as ZIP
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            </Col>
        </Row>
    </>
    );
}