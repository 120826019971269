import React, { useEffect, useState } from "react";
import { useApiUrl } from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";
import { Button, Col, Row, Typography } from "antd";

import { ReviewContentItemDetail, IActionObject, OperationProgress, ReviewContentOverview, User, LoggedIssue, ContentLogEntry } from "interfaces";
import { ReviewTable, ActionFormModal, CustomTabs, IssueTable, IssueFormModal, ActionLegend, Notification, ContentLogModal } from "dashboards/components/admin-dashboard";
import { ReasonModal } from "dashboards/components/team-leader-dashboard";

import { useTitle } from "contexts/TitleContext";
import { QuestionCircleOutlined } from "@ant-design/icons";

import "./tech.css";

const { Title } = Typography;
const abbreviation = {
    "broken_sheet": "Broken sheet - value errors etc.",
    "no_access": "Cannot access sheet",
    "wrong_category": "Categories are wrong",
    "wrong_expedition": "Expeditions are wrong",
    "wrong_level": "Levels are wrong",
    "wrong_textbook": "Textbooks are wrong",
    "wrong_sheet": "Wrong sheet assigned"
};

const client = process.env.REACT_APP_DB_CLIENT as string;
const secret = process.env.REACT_APP_DB_SECRET as string;

interface ResultData {
    status: string;
    messages: string[];
}

export const TechnicalDashboard: React.FC = () => {
    const apiUrl = useApiUrl();
    const [version, setVersion] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState<ReviewContentOverview | null>(null);
    const [issuesData, setIssuesData] = useState<ReviewContentOverview | null>(null);
    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<ReviewContentItemDetail>({} as ReviewContentItemDetail);
    const [users, setUsers] = useState<User[]>([]);
    const [operationId, setOperationId] = useState<number>(0);
    const [operationProgress, setOperationProgress] = useState<OperationProgress>({} as OperationProgress);
    const [revisitComment, setRevisitComment] = useState<string>("");
    const [linkData, setLinkData] = useState<string>("");
    const [resultData, setResultData] = useState<ResultData | null>(null);
    const [loggedIssue, setLoggedIssue] = useState<LoggedIssue[]>([]);
    const [contentLogData, setContentLogData] = useState<ContentLogEntry[]>([]);
    const [reasonModalVisible, setReasonModalVisible] = useState(false);
    const [contentLogModalVisible, setContentLogModalVisible] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const [isLegendModalOpen, setIsLegendModalOpen] = useState(false);

    const handleRowIdClick = async (row_id: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/team_leader/issue-report`, { row_id }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setLoggedIssue(data);
                setReasonModalVisible(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const closeModal = () => {
        setReasonModalVisible(false);
        setContentLogModalVisible(false);
        setLoggedIssue([]);
    }

    const { setTitle } = useTitle();

    useEffect(() => {
        setTitle('Tech Dashboard');
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [reviewResponse, issueResponse] = await Promise.all([
                    axiosInstance.get(`${apiUrl}/dashboard/admin/technical?version=${version}`, {
                        headers: { "Content-Type": "application/json" }
                    }),
                    axiosInstance.get(`${apiUrl}/dashboard/admin/issues?version=${version}`, {
                        headers: { "Content-Type": "application/json" }
                    })
                ]);

                setData(reviewResponse.data);
                setIssuesData(issueResponse.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedItem && selectedItem.subject_id) {
            const fetchTeamLeaderData = async () => {
                try {
                    const response = await axiosInstance.get(
                        `${apiUrl}/dashboard/team_leader/department/list/${selectedItem.subject_id}`
                    );
                    setUsers(response.data);
                } catch (error) {
                    console.error("Error fetching team leader data:", error);
                }
            };

            fetchTeamLeaderData();
        }
    }, [selectedItem]);

    const handleContentLog = async (row_id: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/team_leader/content-log`, { row_id }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setContentLogData(response.data);
                setContentLogModalVisible(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const rawData = data;
    const tableData = rawData?.details as ReviewContentItemDetail[] | undefined ?? [];
    const users_details = rawData?.users as User[] | undefined ?? [];

    const rawIssueData = issuesData;
    const issueTableData = rawIssueData?.details as ReviewContentItemDetail[] | undefined ?? [];

    const combinedData = {
        reviewData: data,
        issueData: issuesData
    };
    const handleActionButtonClick = async (record: string, item: ReviewContentItemDetail) => {

        const isSameItem = selectedItem && selectedItem.id === item.id;
        if (!isSameItem) {
            if (activeTab === 0) {
                setUsers(users_details);
            } else {
                setUsers(users);
            }
        }
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
        setRevisitComment('');
        setResultData(null);
        setShowNotification(false);

        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/subsheetlink`, item, {
                headers: { "Content-Type": "application/json" },
            });
            if (response.status === 200) {
                const body = response.data;
                setLinkData(body);
            }

        } catch (error) {
            console.error("Error fetching link data:", error);
        }
    }
    const handleModal = async (isCancel: boolean, action?: IActionObject) => {
        if (!isCancel && action) {

            if (action.status === "ResolveExtension") {
                await handleResolveExtensionOperation(action);
            }
            else if (action.status === "Simulation") {
                await handleSimulationOperation(action);
            }
            else if (action.status === "Toolboxes") {
                await handleToolboxesOperation(action);
            }
            else if (action.status === "Video Lists") {
                await handleVideoListsOperation(action);
            }
            else if (action.status === "AddUser") {
                await handleAddUserOperation(action);
            }
            else if (action.status === "AddDashUser") {
                await handleAddDashUserOperation(action);
            }
            else if (action.status === "UploadType") {
                await handleUploadTypeOperation(action);
            }
            else if (action.status === "CheckExpedition") {
                await handleCheckExpeditionOperation(action);
            }
            else {
                await handleBeginOperation(action);
            }
        } else {
            setModalStage("initial");
            setOpen(false);
            setRevisitComment('');
        }
    }

    const handleBeginOperation = async (action: IActionObject) => {
        switch (selectedItem.issue_reason) {
            case abbreviation.wrong_category:
                if (selectedItem.content_type === "Video Lists") {
                    await axiosInstance.get(`${apiUrl}/management/update/categories`, {
                        headers: {
                            "Content-Type": "application/json",
                            "client": client,
                            "secret": secret
                        }
                    });
                } else {
                    console.error("Only video_list uses this right now.");
                }
                break;
            case abbreviation.wrong_textbook:
                if (selectedItem.content_type === "Textbooks" || selectedItem.content_type === "Textbook Quiz") {
                    await axiosInstance.get(`${apiUrl}/management/update/textbooks`, {
                        headers: {
                            "Content-Type": "application/json",
                            "client": client,
                            "secret": secret
                        }
                    });
                }
                break;

            default:
                console.log("Other Error");
                break;
        }
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/content`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleAddUserOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/add-drive-user`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleAddDashUserOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/add-dashboard-user`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleResolveExtensionOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/resolve-extension`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleSimulationOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-simulation`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status, // "fail" or "success"
                        messages: [body.result.messages]
                    });
                }
            }

        } catch (error) {
            console.error(error);
        }
    }
    const handleToolboxesOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-toolboxes`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status, // "fail" or "success"
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleVideoListsOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-videolist`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleUploadTypeOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-uploadtype`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleCheckExpeditionOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-quiz`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages]
                    });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getProgress = async () => {
        const response = await axiosInstance.get(`${apiUrl}/dashboard/content/${operationId}/progress`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        setOperationProgress(response.data);
        return response;
    }

    useEffect(() => {
        if (operationId === 0) return;

        let stayOpen = true;
        let elapsedTime = 0;
        const intervalId = setInterval(async () => {
            if (!stayOpen) {
                handleModalClose();
                setOperationId(0);
                setVersion((v) => v + 1);
                clearInterval(intervalId);
            } else {
                try {
                    const response = await getProgress();
                    if (elapsedTime >= 30000 && response.data.percent === 0) {
                        stayOpen = false;
                        setOperationId(0);
                        handleModalClose();
                        clearInterval(intervalId);
                        setResultData({
                            status: "fail",
                            messages: ["Something went wrong. Please try again."]
                        });
                    } else {
                        stayOpen = response.data.percent < 100;
                    }
                    elapsedTime += 1500;
                } catch (error) {
                    console.error(error);
                    setOperationId(0);
                    handleModalClose();
                    clearInterval(intervalId);
                }
            }
        }, 1500);
        return () => clearInterval(intervalId);
    }, [operationId]);

    const handleModalClose = async () => {
        setModalStage("initial");
        setOperationId(0);
        setOperationProgress({} as OperationProgress);
        setOpen(false);
        setRevisitComment('');
        setShowNotification(true);
    }

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        setVersion(v => v + 1);
    };

    const closeNotification = () => {
        setResultData(null);
    };

    const handleLegendModalOpen = () => {
        setIsLegendModalOpen(true);
    }

    const handleLegendModalClose = () => {
        setIsLegendModalOpen(false);
    }

    return (
        <>
            {resultData && showNotification && (
                <Notification data={resultData} onClose={closeNotification} />
            )}
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Technical Dashboard</Title>
                    <CustomTabs activeTab={activeTab} setActiveTab={setActiveTab} combinedData={combinedData} onTabChange={handleTabChange} />
                </Col>
            </Row>
            <Row>
                {activeTab === 0 ? (
                    <ReviewTable
                        tableData={tableData}
                        handleActionButtonClick={handleActionButtonClick}
                        handleContentLog={handleContentLog}
                    />
                ) : (
                    <>
                        <IssueTable
                            tableData={issueTableData}
                            handleActionButtonClick={handleActionButtonClick}
                            handleRowIdClick={handleRowIdClick}
                            handleContentLog={handleContentLog}
                        />
                        {reasonModalVisible &&
                            <ReasonModal
                                visible={reasonModalVisible}
                                loggedIssue={loggedIssue}
                                onClose={closeModal}
                            />
                        }
                    </>
                )}
                {contentLogModalVisible &&
                    <ContentLogModal
                        visible={contentLogModalVisible}
                        logData={contentLogData}
                        onClose={closeModal}
                    />
                }

                {(open && activeTab === 0) ?
                    (
                        <ActionFormModal
                            stage={modalStage}
                            item={selectedItem}
                            users={users}
                            open={open}
                            operationProgress={operationProgress}
                            revisitComment={revisitComment}
                            updateStage={setModalStage}
                            handleModal={handleModal}
                            setRevisitComment={setRevisitComment}

                        />
                    ) : (
                        <IssueFormModal
                            stage={modalStage}
                            item={selectedItem}
                            subsheetId={linkData}
                            users={users}
                            open={open}
                            operationProgress={operationProgress}
                            updateStage={setModalStage}
                            handleModal={handleModal}
                        />
                    )}

                <Button
                    type="primary"
                    shape="circle"
                    icon={<QuestionCircleOutlined />}
                    onClick={handleLegendModalOpen}
                    className="help-button"
                    size="large"
                />
            </Row>
            {isLegendModalOpen && <ActionLegend
                open={isLegendModalOpen}
                onOkClick={handleLegendModalClose}
                onCancelClick={handleLegendModalClose}
            />}
        </>
    )
};