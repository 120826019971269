import { Row, Col, Typography, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useLogout } from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";
import { useEffect, useState } from "react";

import { API_URL } from "utils/constants";
import { extractSimplifiedName } from "utils/functions";

import { AssignmentCard, AssignmentTable, AssignmentEdit } from "hs-marking/components";
import { IAssignment, ICourseAssignments, IRubric, ISubmission, StudentCourseOverview } from "interfaces";
import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const AssignmentList: React.FC = () => {
    const { id, course_id } = useParams();
    const { mutate: logout } = useLogout();
    const [courseData, setCourseData] = useState<ICourseAssignments | null>(null);
    const [singleCourseCount, setSingleCourseCount] = useState<StudentCourseOverview | null>(null);
    const [selectedAssignment, setSelectedAssignment] = useState<IAssignment | null>(null);
    const [selectedAssignmentScroll, setSelectedAssignmentScroll] = useState<any | null>(null);
    const [scrollData, setScrollData] = useState<ISubmission | null>(null);
    const [selectedRubric, setSelectedRubric] = useState<IRubric[] | null>(null);
    const [submissionData, setSubmissionData] = useState<ISubmission | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(true);

    const counts = singleCourseCount;
    const student_name = singleCourseCount?.student?.name;
    const course = courseData as unknown as ICourseAssignments;

    const c_name = extractSimplifiedName(courseData?.name || '');
    const assignments: IAssignment[] = courseData?.assignments || [];
    const terms: string[] = Array.from(new Set(course?.assignments.map(assignment => assignment.grading_period)));

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${c_name ?? "View course"}`);
    }, [courseData]);

    const fetchCourseData = async () => {
        setLoading(true); 
        setTableLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/students/${id}/courses/${course_id}`);
            if (response.status === 200) {
                setCourseData(response.data);
            }
        } catch (error) {
            console.error("Error fetching course data:", error);
        } finally {
            setLoading(false);
            setTableLoading(false);
        }
    };

    const fetchCourseOverview = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/students/${id}/courses/${course_id}/overview`);
            if (response.status === 200) {
                setSingleCourseCount(response.data);
            }
        } catch (error) {
            console.error("Error fetching course overview:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCourseData();
        fetchCourseOverview();
    }, [id, course_id]);

    const handleExcuseClick = async (row: any) => {
        try {
            const row_id = row.submission.id;
            const response = await axiosInstance.post(`${API_URL}/students/excused`, { row_id }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const data = response.data;
                await fetchCourseData();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleMultipleExcuse = async (submissionIds: any) => {
        try {
            const response = await axiosInstance.post(`${API_URL}/students/multiple-excused`, { submissionIds }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                await fetchCourseData();
            }
        } catch (error) {
            console.error(error)
        }
    };

    const fetchAssignmentDetails = async (assignment_id: number) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/students/${id}/courses/${course_id}/assignments/${assignment_id}`);
            if (response.status === 200) {
                setSelectedAssignment(response.data);
            }
        } catch (error) {
            console.error("Error fetching assignment details:", error);
        }
    };

    const fetchRubrics = async (assignment_id: number) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/students/${id}/courses/${course_id}/assignments/${assignment_id}/rubrics`);
            if (response.status === 200) {
                setSelectedRubric(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSubmission = async (assignment_id: number) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/students/${id}/courses/${course_id}/assignments/${assignment_id}/submissions/${id}`);
            if (response.status === 200) {
                setSubmissionData(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchScroll = async (assignment_id: number) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/students/${id}/courses/${course_id}/assignments/${assignment_id}`);
            if (response.status === 200) {
                setScrollData(response.data);
            }
        } catch (error) {
            console.error("Error fetching scroll:", error);
        }
    };

    const handleSave = async (updatedAssignment: IAssignment) => {
        try {
            const response = await axiosInstance.patch(
                `${API_URL}/students/${id}/courses/${course_id}/assignments/${updatedAssignment.id}`,
                { Grade: updatedAssignment.submission.score?.toString() },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.status >= 200 && response.status < 300) {
                await axiosInstance.post(
                    `${API_URL}/students/change-excused-status`,
                    { submission_id: updatedAssignment.submission.id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );
                await fetchCourseData();
                setSelectedAssignment(null);
            } else {
                throw new Error(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error updating assignment:', error);
        }
    };

    const handleAssignmentClick = (assignment_id: number) => {
        fetchAssignmentDetails(assignment_id);
        fetchSubmission(assignment_id);

        const assignment = assignments.find(a => a.id === assignment_id);
        const lowerCaseName = assignment?.name?.toLowerCase() ?? "";
        const isRubrics = lowerCaseName.includes("tribe council") || lowerCaseName.includes("myclues") || lowerCaseName.includes("map") || lowerCaseName.includes("scroll") || lowerCaseName.includes("homework discussion") || lowerCaseName.includes("marking");
        if (isRubrics) {
            fetchRubrics(assignment_id);
        }

        const getBaseName = (name: string) => {
            const nameWithoutTime = name.replace(/\s*\(.*?\)$/, '').trim();
            return nameWithoutTime;
        };

        if (assignment) {
            const match = assignment.name.match(/(\w+ \d+\.\d+ ERRAND|\w+ \d+\.\d+ QUEST)/);
            if (match) {
                const snippedName = match[1];
                const scrollAssignment = assignments.find(a => a.name.includes(`${snippedName} SCROLL`));
                if (scrollAssignment) {
                    setSelectedAssignmentScroll(scrollAssignment);
                    fetchScroll(scrollAssignment.id);
                }
            }
            else{
                const baseName = getBaseName(assignment.name);
                const markingAssignment = assignments.find(a => a.name.includes(`${baseName} Marking`));
                if (markingAssignment) {
                    setSelectedAssignmentScroll(markingAssignment);
                    fetchScroll(markingAssignment.id);
                }  else {
                    const specificMarkingAssignment = assignments.find(a => {
                        const normalizedAssignmentName = assignment.name.replace(/\s*\(.*?\)$/, '').trim();
                        return normalizedAssignmentName.startsWith(baseName) &&
                               a.name.includes("Marking") &&
                               a.name.match(/^\w+ W\d+/);
                    });
                    if (specificMarkingAssignment) {
                        setSelectedAssignmentScroll(specificMarkingAssignment);
                        fetchScroll(specificMarkingAssignment.id);
                    }
                }
            }
        }
    };

    const handleModalClose = () => {
        setSelectedAssignment(null);
        setSubmissionData(null);
    };

    const isEPFCourse = courseData?.name.toLowerCase().includes("epf") || false;

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    {!tableLoading  && (
                        <Col span={24}>
                            <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{`${student_name}: ${c_name}`}</Title>
                        </Col>
                    )}
                </Col>
                <Col span={4}>
                    <AssignmentCard overview={counts} />
                </Col>
            </Row>
            <Row>
            {tableLoading  ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh',
                    width: '100vw'
                }}>
                    <Spin size="large" />
                    <p style={{ marginTop: '16px' }}>Loading Assignments...</p>
                </div>
            ):(
                <AssignmentTable 
                    assignments={assignments} 
                    handleExcuseClick={handleExcuseClick} 
                    onAssignmentClick={handleAssignmentClick} 
                    handleMultipleExcuse={handleMultipleExcuse} 
                    isEPFCourse={isEPFCourse}
                />
            )}
            </Row>
            {selectedAssignment && (
                <AssignmentEdit 
                    assignment={selectedAssignment} 
                    scroll={selectedAssignmentScroll} 
                    scrollData={scrollData} 
                    submission={submissionData} 
                    rubric={selectedRubric} 
                    onClose={handleModalClose} 
                    onSave={handleSave} 
                />
            )}
        </>
    );
};
