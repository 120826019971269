import React, { useEffect, useMemo, useState } from 'react';
import { IAssignment } from 'interfaces';
import { Button, Col, Select, Tooltip } from 'antd';
import moment from 'moment';
import { StyledTable } from './styled';
import { getCourseStatusColor, getExcusedBoxClass } from 'utils/functions';
import { Link, useParams } from 'react-router-dom';
import { ExcuseModal } from '../ExcusedModal';

const { Option } = Select;

interface AssignmentTableProps {
  assignments: IAssignment[];
  handleExcuseClick: (data: any) => void;
  handleMultipleExcuse: (submissionIds: any) => void;
  onAssignmentClick: (record_id: number) => void;
  isEPFCourse: boolean;
}

const extractSectionNumber = (name: string, isEPFCourse: boolean) => {
  if (isEPFCourse) {
    const examMatch = name.match(/(EW\d+)/);
    if (examMatch) {
      const examNumber = parseInt(examMatch[1].replace('EW', ''), 10);
      return `Exam Week ${examNumber}`;
    }
    const weekMatch = name.match(/(W\d+)/);
    if (weekMatch) {
      const weekNumber = parseInt(weekMatch[1].replace('W', ''), 10);
      return `Week ${weekNumber}`;
    }
    return 'Uncategorized';
  }
  const parts = name.split(' ');
  return parts.length > 1 ? parts[1] : 'Uncategorized';
};

const extractCategory = (name: string, isEPFCourse: boolean) => {
  if (isEPFCourse) {
    const cleanedName = name.replace(/^[^ ]+\s[^ ]+\s*/, '').replace(/\s*\(.*?\)/, '').replace(/\bAS Level\b\s*/, '').replace('Exam Paper ', ''); 
    return cleanedName;
  }

  const parts = name.split('|');
  if (parts.length > 1) {
    const categoryPart = parts[0].trim();
    const categoryParts = categoryPart.split(' ');
    return categoryParts.slice(2).join(' ').trim();
  }
};

const extractExpeditionTitle = (name: string) => {
  const parts = name.split('|');
  const titleWithBrackets = parts.length > 1 ? parts[1].trim() : '';
  const cleanTitle = titleWithBrackets.split('(')[0].trim();
  return cleanTitle;
};

const groupAssignmentsBySection = (assignments: IAssignment[], isEPFCourse: boolean) => {
  const grouped: any = assignments.reduce((acc: any, item: IAssignment) => {
    const section = extractSectionNumber(item.name, isEPFCourse);
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(item);
    return acc;
  }, {});

  Object.keys(grouped).forEach((section) => {
    grouped[section].sort((a: IAssignment, b: IAssignment) => {
      return moment(b.due_at).diff(moment(a.due_at));
    });
  });

  const sortedSections = Object.keys(grouped).sort((a, b) => {
    if (isEPFCourse) {
      return parseFloat(a.replace('W', '')) - parseFloat(b.replace('W', ''));
    } else {
      return parseFloat(b) - parseFloat(a);
    }
  });

  const sortedGroupedData: any = {};
  sortedSections.forEach((section) => {
    sortedGroupedData[section] = grouped[section];
  });

  return sortedGroupedData;
};

export const AssignmentTable: React.FC<AssignmentTableProps> = ({
  assignments = [],
  handleExcuseClick,
  handleMultipleExcuse,
  onAssignmentClick,
  isEPFCourse
}) => {
  const currentDate = moment();
  const [selectedGradingPeriod, setSelectedGradingPeriod] = useState<string | undefined>("Term 3");
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState<IAssignment | null>(null);

  const gradingPeriods = useMemo(() => {
    const periods = Array.from(new Set(assignments.map(a => a.grading_period).filter(Boolean)));
    return periods.sort((a, b) => a.localeCompare(b));
  }, [assignments]);
  
  useEffect(() => {
    const currentGradingPeriodAssignments = assignments.filter(assignment => 
      assignment.grading_period === selectedGradingPeriod
    );
  
    const dueAssignments = currentGradingPeriodAssignments.filter(assignment => 
      moment(assignment.due_at).isAfter(currentDate)
    );
  
    if (dueAssignments.length > 0) {
      const dueTerm = dueAssignments[0].grading_period;
      setSelectedGradingPeriod(dueTerm);
    } else {
      const futureAssignments = assignments.filter(assignment => 
        moment(assignment.due_at).isAfter(currentDate)
      );
  
      if (futureAssignments.length > 0) {
        const nextTerm = futureAssignments[0].grading_period;
        setSelectedGradingPeriod(nextTerm);
      } else if (gradingPeriods.length > 0) {
        const latestTerm = gradingPeriods[gradingPeriods.length - 1];
        setSelectedGradingPeriod(latestTerm);
      }
    }
  }, [assignments, gradingPeriods]);
  
  const filteredAssignments = assignments
  .filter((assignment) =>
    (assignment.submission.workflow_state !== 'unsubmitted') ||
    (moment(assignment.due_at).isBefore(currentDate))
  )
  .filter((assignment) =>
    !/(HOLOGRAM|Boosters|Books of Knowledge|Tasks|Chronicles|Multiple Choice)/i.test(assignment.name)
  );

const assignmentNames = useMemo(() => Array.from(new Set(filteredAssignments.map(a => extractCategory(a.name, isEPFCourse)))), [filteredAssignments, isEPFCourse]);

const furtherFilteredAssignments = filteredAssignments
  .filter((assignment) =>
    !selectedGradingPeriod || assignment.grading_period === selectedGradingPeriod
  )
  .filter((assignment) =>
    selectedNames.length === 0 || selectedNames.some(name => extractCategory(assignment.name, isEPFCourse) === name)
  )
  .sort((a: IAssignment, b: IAssignment) => moment(a.due_at).diff(moment(b.due_at)));
  const groupedData = groupAssignmentsBySection(furtherFilteredAssignments, isEPFCourse);

  const { course_id } = useParams();

  const showModal = (assignment: IAssignment) => {
    setSelectedAssignment(assignment);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedAssignment) {
      handleExcuseClick(selectedAssignment);
    }
    setIsModalVisible(false);
    setSelectedAssignment(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedAssignment(null);
  };

  const handleResetFilters = () => {
    setSelectedGradingPeriod(undefined);
    setSelectedNames([]);
  };

  const handleExcuseAll = () => {
    const submissionIds = furtherFilteredAssignments.map(assignment => assignment.submission.id);
    handleMultipleExcuse(submissionIds);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: isEPFCourse ? 650 : 450,
      onCell: (record: any, rowIndex: any) => ({
        className: record.submission.excused === false ? 'disabled' : '',
        style: {
          backgroundColor: getCourseStatusColor(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(record.id),
        onMouseLeave: () => handleMouseLeaveConnected(record.id),
      }),
      render: (text: string, record: any) => (
        <Link
          to={`https://saintsburg.instructure.com/courses/${course_id}/assignments/${record.id}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: '#000' }}
        >
          <div className={`section-cell connected assignment-row-${record.id}`}>
            <span style={{ paddingLeft: '1rem' }}>{extractCategory(text, isEPFCourse)}</span>
          </div>
        </Link>
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'due_at',
      key: 'due_at',
      width: 550,
      onCell: (record: any, rowIndex: any) => ({
        className: record.submission.excused === false ? 'disabled' : '',
        style: {
          backgroundColor: getCourseStatusColor(record),
        },
      }),
      render: (text: string) => (text ? moment(text).format('D MMM YYYY') : ''),
    },
    {
      title: 'Excuse',
      key: 'excuse',
      width: 250,
      onCell: (record: any) => ({
        style: {
          backgroundColor: getCourseStatusColor(record),
        },
        onClick: () => {
          if (record.submission.excused !== false) {
            showModal(record);
          }
        },
      }),
      render: (text: string, record: any) => (
        <div className={getExcusedBoxClass(record)}>
          {record.submission.excused === false ? 'Excused' : 'Excuse'}
        </div>
      ),
    },
    {
      title: 'OR',
      key: 'or',
      width: 50,
      onCell: (record: any, rowIndex: any) => ({
        className: record.submission.excused === false ? 'disabled' : '',
        style: {
          backgroundColor: getCourseStatusColor(record),
        },
      }),
      render: () => <span>OR</span>,
    },
    {
      title: 'Score / Points Possible',
      key: 'score_points_possible',
      width: 200,
      onCell: (record: any, rowIndex: any) => ({
        className: record.submission.excused === false ? 'disabled' : '',
        style: {
          backgroundColor: getCourseStatusColor(record),
        },
      }),
      render: (text: string, record: any) => (
        <div>
          <Tooltip title="Grade">
            <span
              className="section-cell"
              style={{ padding: '0 1rem', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                onAssignmentClick(record.id);
              }}
            >
              {record.submission.score !== null
                ? Number.isInteger(record.submission.score)
                ? record.submission.score
                : record.submission.score.toFixed(2)
                : ''
              }
            </span>
          </Tooltip>
          <span> /{record.points_possible}</span>
        </div>
      ),
    },
  ];

  const handleMouseEnterConnected = (assignmentId: string) => {
    const connected = document.querySelectorAll(`.assignment-row-${assignmentId} .connected`);
    connected.forEach((element) => {
      element.classList.add('hover-effect');
    });
  };

  const handleMouseLeaveConnected = (assignmentId: string) => {
    const connected = document.querySelectorAll(`.assignment-row-${assignmentId} .connected`);
    connected.forEach((element) => {
      element.classList.remove('hover-effect');
    });
  };

  return (
    <Col span={20}>
      <div style={{ marginBottom: '1rem' }}>
        <Select
          placeholder="Select Term"
          style={{ width: '200px' }}
          value={selectedGradingPeriod}
          onChange={(value) => setSelectedGradingPeriod(value)}
        >
          {gradingPeriods.map((period) => (
            <Option key={period} value={period}>
              {period}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          placeholder="Filter by Assignment"
          style={{ width: '200px', marginLeft: '1rem' }}
          value={selectedNames}
          onChange={(value) => setSelectedNames(value)}
        >
          {assignmentNames.map((name) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
        <Button
          type="default"
          style={{ marginLeft: '1rem' }}
          onClick={handleResetFilters}
          disabled={!selectedGradingPeriod && selectedNames.length === 0}
        >
          Reset
        </Button>
        {selectedGradingPeriod && selectedNames.length > 0 && (
          <Button
            type="primary"
            style={{ marginLeft: '1rem' }}
            onClick={handleExcuseAll}
          >
            Excuse All
          </Button>
        )}
      </div>
      {Object.keys(groupedData).length === 0 ? (
        <div style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 'bold',
          marginTop: '50px',
          color: '#555'
        }}>
          No Assignments Found
        </div>
      ) : (
        Object.keys(groupedData).map((section) => (
          <div key={section} style={{ marginBottom: '2rem' }}>
            <h2 style={{ textAlign: 'center', color: 'white', backgroundColor: 'black', padding: '0.5rem', borderRadius: '20px' }}>
              {section} {extractExpeditionTitle(groupedData[section][0].name)}
            </h2>
            <StyledTable
              columns={columns}
              dataSource={groupedData[section]}
              pagination={false}
              showHeader={false}
              rowKey="id"
              onRow={(record) => ({
                className: record.submission.excused === false ? 'disabled' : `assignment-row-${record.id}`,
              })}
            />
          </div>
        ))
      )}
      <ExcuseModal
        isVisible={isModalVisible}
        selectedAssignment={selectedAssignment}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
    </Col>
  );
};
