import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTitle } from 'contexts/TitleContext';
import { CreateQuest, CreateScroll } from 'assignment-builder/components';

export const AssignmentCreate: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const assignmentType = searchParams.get('type');

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`Create ${assignmentType === 'quest' ? 'Quest' : 'Scroll'}`);
    }, []);

    return (assignmentType === 'quest' ? <CreateQuest /> : assignmentType === 'scroll' ? <CreateScroll /> : <></>);
};
