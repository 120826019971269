import React, { useEffect } from "react";
import { HttpError, useList } from "@refinedev/core";
import { Table, Button, Tag } from "antd";
import { Show } from "@refinedev/antd";
import { EyeOutlined } from '@ant-design/icons';

import moment from 'moment';

import { PdfPaper } from "interfaces";
import { useTitle } from "contexts/TitleContext";

type PaperStatus = 'Assigned' | 'In progress' | 'Finalised' | 'Reviewing';

const statusColors: Record<PaperStatus, string> = {
    'Assigned': 'blue',
    'In progress': 'gold',
    'Finalised': 'green',
    'Reviewing': 'purple'
};

export const PaperList: React.FC = () => {
    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Papers');
    }, []);

    const { data, isLoading } = useList<PdfPaper, HttpError>({
        resource: "pdf",
    });

    const papers = data?.data ?? [];

    const columns = [
        {
            title: 'Paper Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: PdfPaper, b: PdfPaper) => a.name.localeCompare(b.name),
        },
        {
            title: 'Date Assigned',
            dataIndex: 'date_assigned',
            key: 'date_assigned',
            render: (date: string) => moment(date).format('D MMMM YYYY'),
            sorter: (a: PdfPaper, b: PdfPaper) => a.date_assigned.localeCompare(b.date_assigned),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: PaperStatus) => (
                <Tag color={statusColors[status]} key={status}>
                    {status.toUpperCase()}
                </Tag>
            ),
            sorter: (a: PdfPaper, b: PdfPaper) => a.status.localeCompare(b.status),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (paper: PdfPaper) => (
                <Button
                    href={`papers/${paper.name}`}
                    type="primary"
                    icon={<EyeOutlined/>}
                >
                    View
                </Button>
            ),
        },
    ];

    return (
        <Show
            title="Papers"
            isLoading={isLoading}>
            <Table columns={columns} dataSource={papers} />
        </Show>
    )
};