import React, { useEffect, useState } from "react";
import { useApiUrl, useList } from "@refinedev/core";
import { useLocation, useParams } from "react-router-dom";
import { axiosInstance } from "@refinedev/simple-rest";
import { Col, message, Row, Typography } from "antd";
import { DesktopOutlined } from "@ant-design/icons";

import { BreadCrumbModule, ToDoCard, PastPapersTable } from "dashboards/components/team-leader-dashboard";
import { PaperActionFormModal } from "dashboards/components/admin-dashboard";
import { Developer, IPaperActionObject, PaperItemDetail, ToDoData } from "interfaces";

import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const PastPapersOverview: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const apiUrl = useApiUrl();

    const [version, setVersion] = useState(0);

    const { data } = useList<PaperItemDetail>({
        resource: `dashboard/admin/papers?version=${version}&`
    });

    const { data: userData } = useList<Developer>({
        resource: `dashboard/team_leader/department/list/${location.state.subject_id}`
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: "dashboard/team_leader/todo",
    });

    const subjectId = id !== undefined ? parseInt(id, 10) : -1;
    const tableData = data?.data?.filter((item) => item.subject_id === subjectId) ?? [];
    const users = userData?.data ?? [];
    const toDo = toDoData?.data as ToDoData | undefined;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${location.state.content_type_name ?? "Content"} (${location.state.subject ?? "Overview"})`);
    }, []);

    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<PaperItemDetail>({} as PaperItemDetail);
    const [messageApi, contextHolder] = message.useMessage();

    const handleActionButtonClick = (item: PaperItemDetail) => {
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
    }

    const handleModal = async (isCancel: boolean, action?: IPaperActionObject) => {
        if (!isCancel && action) {
            handleBeginOperation(action);
        }
        else {
            setModalStage("initial");
            setOpen(false);
        }
    }

    const handleBeginOperation = async (action: IPaperActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/admin/paper`, { paper: action }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.code === 200) {
                    messageApi.open({
                        type: 'success',
                        content: body.message
                    });
                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: body.message
                    });
                }
            }
            handleModalClose();
            setVersion(v => v + 1);
        } catch (error) {
            console.error(error);
            handleModalClose();
        }
    }

    const handleModalClose = async () => {
        setModalStage("initial");
        setOpen(false);
    }

    const breadCrumbItems = [
        { href: "/dashboard", title: <><DesktopOutlined /><span>Dashboard</span></> },
        { href: `/dashboard/past-papers/${id}`, title: "Past Papers" },
    ];

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{location.state.content_type_name} ({location.state.subject})</Title>
                    </Col>
                    <Col span={24} style={{ marginLeft: '10px', marginTop: '20px' }}>
                        <BreadCrumbModule items={breadCrumbItems} />
                    </Col>
                </Col>
                <Col span={4}>
                    <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
                </Col>
            </Row>
            <Row>
                <PastPapersTable
                    tableData={tableData}
                    handleActionButtonClick={handleActionButtonClick}
                />
                {open && selectedItem && (
                    <PaperActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                    />
                )}
            </Row>
        </>
    )
};