import React, { useState } from "react";
import { AutoComplete, Button, Col, ConfigProvider, DatePicker, Form, Row, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SolutionOutlined, CheckOutlined, PauseOutlined } from '@ant-design/icons';

import { IPaperActionObject, PaperItemDetail, Splitter } from "interfaces";

import { FormButton, StyledModal } from "../../actionmodal.styled";

dayjs.extend(customParseFormat);

interface IFormModalProps {
    stage: string;
    item: PaperItemDetail;
    users: Splitter[];
    open: boolean;
    updateStage: React.Dispatch<React.SetStateAction<string>>;
    handleModal: (isCancel: boolean, action?: IPaperActionObject) => void;
}

interface IInitialStageProps {
    item: PaperItemDetail;
    handleButtonClick: (clickAction: string, nextStage?: string) => void;
}

interface IAssignStageProps {
    selectedUser: Splitter;
    users: Splitter[];
    dueDate: string;
    setDueDate: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<Splitter>>;
}

interface IColorButtonProps {
    btnColor: string;
    btnNode: React.ReactNode;
}

const ColorButton = ({ btnColor, btnNode }: IColorButtonProps) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: btnColor,
                },
            }}
        >
            {btnNode}
        </ConfigProvider>
    )

}
const InitialStage = ({ item, handleButtonClick }: IInitialStageProps) => {
    return (
        <Row gutter={0}>
            <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
                <FormButton
                    icon={<SolutionOutlined />}
                    onClick={() => handleButtonClick('Assign', 'assign')}
                    type="primary"
                    style={{ width: '75%' }}
                >
                    Assign
                </FormButton>
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <ColorButton
                    btnColor={'#ffbd59'}
                    btnNode={<FormButton
                        icon={<PauseOutlined />}
                        onClick={() => handleButtonClick('On Hold')}
                        type="primary"
                        style={{ width: '75%' }}
                    >
                        On hold
                    </FormButton>}
                />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'start' }}>
                <ColorButton
                    btnColor={'#00b96b'}
                    btnNode={<FormButton
                        icon={<CheckOutlined />}
                        onClick={() => handleButtonClick('Finalise')}
                        type="primary"
                        style={{ width: '75%' }}
                    >
                        Finalise
                    </FormButton>}
                />
            </Col>
        </Row >
    )

}

const AssignStage = ({ selectedUser, users, dueDate, setDueDate, setUser }: IAssignStageProps) => {
    const dateFormat = 'DD-MM-YYYY';

    const handleSelect = (id: string) => {
        if(!id){
            setUser({id: 0, name: ""});
            return;
        }
        const sUser = users?.filter(ele => ele.id+"" === id+"")[0];
        console.log(sUser)
        setUser(sUser);
    }

    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Assign to" rules={[{ required: true, message: "Please select a user" }]}>
                <AutoComplete
                    placeholder="Enter name"
                    onChange={handleSelect}
                    options={users?.map((ele) => ({ value: ele.id, label: ele.name }))}
                    allowClear
                    value={selectedUser?.name}
                    filterOption={(inputValue, option) =>
                        option!.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                    }
                />
            </Form.Item>
            <Form.Item
                label="Due date"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Please select a due date')),
                    },
                ]}>
                <DatePicker
                    placeholder="Select due date"
                    value={dueDate ? dayjs(dueDate, 'DD/M/YYYY') : ""}
                    onChange={(date: any) => setDueDate(date ? date.format("DD/M/YYYY") : "")}
                    format={dateFormat}
                    minDate={dayjs()}
                />
            </Form.Item>
        </Form>
    )
}

export const PaperActionFormModal = ({
    stage,
    item,
    users,
    open,
    handleModal,
    updateStage,
}: IFormModalProps) => {
    const [user, setUser] = useState<Splitter>({ id: 0, name: "" });
    const [dueDate, setDueDate] = useState<string>(dayjs().add(7, 'day').format('DD/M/YYYY'));
    const [selectedAction, setSelectedAction] = useState<string>('');

    const handleButtonClick = (clickAction: string, nextStage = "initial") => {
        setSelectedAction(clickAction);
        updateStage(nextStage);
        if (nextStage === "initial") {
            handleModal(false, { id: item.id, status: clickAction, user_id: null, assigned_due: null });
        }
    }

    const onOkClick = () => {
        if (stage === "assign") {
            const selectedUser = users?.filter(ele => ele.id === user.id)[0];
            if (!selectedUser || !selectedUser?.id || !dueDate) {
                handleModal(true);
            }
            else {
                handleModal(false, {
                    status: selectedAction,
                    id: item.id,
                    ...((selectedUser?.id || dueDate) && {
                        user_id: selectedUser?.id,
                        assigned_due: dueDate,
                    })
                })
            }
        }
        else {
            handleModal(true);
        }
    }

    const onCancelClick = () => {
        handleModal(true);
    }

    var footer: JSX.Element[] = [];
    if (["initial", "assign"].includes(stage)) {
        footer.push(<Button key="back" onClick={onCancelClick} style={{ borderRadius: '15px' }}>
            Cancel
        </Button>)
    }
    if (["initial", "assign"].includes(stage)) {
        footer.push(<Button key="submit" type="primary" disabled={stage == "initial"} onClick={onOkClick} style={{ borderRadius: '15px' }}>
            Confirm
        </Button>)
    }

    const title: string = {
        "initial": "Perform action",
        "assign": "Perform action",
    }[stage] ?? "";

    return (
        <StyledModal
            open={open}
            onOk={onOkClick}
            onCancel={onCancelClick}
            title={title}
            centered
            footer={footer}
        >
            {stage === "initial" ? <InitialStage item={item} handleButtonClick={handleButtonClick} />
                : <AssignStage
                    selectedUser={user}
                    users={users}
                    dueDate={dueDate}
                    setDueDate={setDueDate}
                    setUser={setUser}
                />
            }
        </StyledModal>
    );
}