import React, { useEffect } from "react";
import { useList } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Col, Row, Typography } from "antd";

import { ToDoCard, SubjectTable } from "dashboards/components/team-leader-dashboard";
import { CourseSubjectContent, SubjectContentItem, ToDoData } from "interfaces";

import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const SubjectOverview: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data, isLoading } = useList({
        resource: `dashboard/team_leader/subject/${id}`,
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: `dashboard/team_leader/todo/${id}`,
    });

    const tableData = data?.data as CourseSubjectContent | undefined;
    const overview = tableData?.overview as SubjectContentItem[] | undefined ?? [];
    const toDo = toDoData?.data as ToDoData | undefined;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${tableData?.subject ?? "Subject Dashboard"}`);
    }, []);

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{tableData?.subject}</Title>
                </Col>
                <Col span={4}>
                    <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
                </Col>
            </Row>
            <Row>
                <SubjectTable tableData={overview} subjectId={id ?? ""} subjectName={tableData?.subject ?? ""} />
            </Row>
        </>
    )
};