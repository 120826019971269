
import { Button, Col, ConfigProvider, DatePicker, Form, Progress, ProgressProps, Row, Select, Space } from "antd";
import { SolutionOutlined, CheckOutlined, WarningOutlined, LinkOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { ContentItemDetail, IActionObject, OperationProgress, ReviewContentItemDetail, User } from "interfaces";
import dayjs from "dayjs";
import { CSSTransition } from 'react-transition-group';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FormButton, ProgressContains, ProgressMessage, StyledModal } from "../../actionmodal.styled";
import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";
import { buildDrivetUrl } from "utils/functions";

dayjs.extend(customParseFormat);

interface IFormModalProps {
    stage: string;
    item: ContentItemDetail | ReviewContentItemDetail;
    users: User[];
    open: boolean;
    operationProgress: OperationProgress;
    revisitComment: string;
    updateStage: React.Dispatch<React.SetStateAction<string>>;
    handleModal: (isCancel: boolean, action?: IActionObject) => void;
    setRevisitComment : React.Dispatch<React.SetStateAction<string>>;
}

interface IInitialStageProps {
    item: ContentItemDetail | ReviewContentItemDetail;
    handleButtonClick: (clickAction: string, nextStage?: string) => void;
}

interface IAssignStageProps {
    users: User[];
    setDueDate: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<number>>;
}

interface RevisitStageProps {
    revisitComment: string;
    setRevisitComment: React.Dispatch<React.SetStateAction<string>>;
}

interface IProgressProps {
    operationProgress: OperationProgress;
}

interface IColorButtonProps {
    btnColor: string;
    btnNode: React.ReactNode;
}

interface CustomLinkButtonProps {
    url: string;
    color: string;
    text: string;
}

const CustomLinkButton: React.FC<CustomLinkButtonProps> = ({ url, color, text }) => (
    <Link to={url} target='_blank' rel='noopener noreferrer' style={{ width: '100%' }}>
        <Button
            type="primary"
            style={{ width: '100%', backgroundColor: color, borderColor: color, borderRadius: '15px' }}
            icon={<LinkOutlined />}
        >
            {text}
        </Button>
    </Link>
);

const ColorButton = ({ btnColor, btnNode }: IColorButtonProps) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: btnColor,
                },
            }}
        >
            {btnNode}
        </ConfigProvider>
    )

}
const InitialStage = ({ item, handleButtonClick }: IInitialStageProps) => {
    const disabledRules = {
        "Assign": false,
        "Update": item.status !== 'Complete',
        "Approve": !(['Needs Approval', 'In Progress', 'Reassigned', 'Overdue'].includes(item.status)),
        "Set N/A": ['Not Needed', 'Not Required'].includes(item.status),
    }
    const showButton = ["Class Notes", "Study Notes", "Class Printing"].includes(item.content_type ?? "");
    const simulationListsButton = ["Simulation Lists"].includes(item.content_type ?? "");
    const toolboxButton = ["Toolboxes"].includes(item.content_type ?? "");
    const videoList = ["Video Lists"].includes(item.content_type ?? "");
    const upload_types = ["Class Notes", "Class Printing", "Errands", "Quests"].includes(item.content_type ?? "");
    const expedition_check = ["Boosters (Write)", "Study Notes Quiz (Write)", "Textbook Quiz"].includes(item.content_type ?? "");

    const getValidateButtonProps = () => {
        let buttonProps = {
            label: 'Validate',
            onClickAction: 'Validate',
            color: '#008CBA',
        };
        if (simulationListsButton) {
            buttonProps = { label: 'Validate', onClickAction: 'Simulation', color: '#008CBA' };
        }
        if (toolboxButton){
            buttonProps = { label: 'Validate', onClickAction: 'Toolboxes', color: '#008CBA' };
        }
        if (videoList) {
            buttonProps = { label: 'Validate', onClickAction: 'Video Lists', color: '#008CBA' };
        }
        if (upload_types) {
            buttonProps = { label: 'Validate', onClickAction: 'UploadType', color: '#008CBA' };
        }
        if (expedition_check) {
            buttonProps = { label: 'Validate', onClickAction: 'CheckExpedition', color: '#008CBA' };
        }
        return buttonProps;
    };
    const validateButtonProps = getValidateButtonProps();

    return (
        <Row gutter={[8, 16]} justify="center">
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <ColorButton
                    btnColor={'#00b96b'}
                    btnNode={<FormButton
                        icon={<CheckOutlined />}
                        onClick={() => handleButtonClick('Prepped', 'process')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Mark Prepped
                    </FormButton>}
                />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <ColorButton
                    btnColor={'#f57c00'}
                    btnNode={<FormButton
                        icon={<WarningOutlined />}
                        onClick={() => handleButtonClick('Revisit', 'revisit')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Flag Revisit
                    </FormButton>}
                />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
                <FormButton
                    icon={<SolutionOutlined />}
                    onClick={() => handleButtonClick('Assign', 'assign')}
                    type="primary"
                    disabled={disabledRules['Assign']}
                    style={{ width: '100%' }}
                >
                    Assign
                </FormButton>
            </Col>
            {validateButtonProps && (
                <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
                    <ColorButton
                        btnColor={validateButtonProps.color}
                        btnNode={<FormButton
                            icon={<IssuesCloseOutlined />}
                            onClick={() => handleButtonClick(validateButtonProps.onClickAction, 'process')}
                            type="primary"
                            style={{ width: '100%' }}
                        >
                            {validateButtonProps.label}
                        </FormButton>}
                    />
                </Col>
            )}
            {showButton && (
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomLinkButton
                        url={buildDrivetUrl(item.upload_folder_id)}
                        color="#3b5998"
                        text="Link to Folder"
                    />
                </Col>
            )}
            {showButton && (
                <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
                    <ColorButton
                        btnColor={'#029994'}
                        
                        btnNode={<FormButton
                            icon={<IssuesCloseOutlined />}
                            onClick={() => handleButtonClick('ResolveExtension', 'process')}
                            type="primary"
                            style={{ width: '100%' }}
                        >
                            Resolve Extension
                        </FormButton>}
                    />
                </Col>
            )}
        </Row>
    )

}

const AssignStage = ({ users, setDueDate, setUser }: IAssignStageProps) => {
    const dateFormat = 'DD-MM-YYYY';
    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Assign to" rules={[{ required: true, message: "Please select a user" }]}>
                <Select onChange={(e) => setUser(e)}>
                    {users?.map((ele) => {
                        return <Select.Option value={ele.id} key={`${ele.name}-${ele.id}`}>{ele.name}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Due date"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Please select a due date')),
                    },
                ]}>
                <DatePicker
                    placeholder="Select due date"
                    onChange={(date: any) => setDueDate(date.format("DD/M/YYYY"))}
                    format={dateFormat}
                    minDate={dayjs()}
                />
            </Form.Item>
        </Form>
    )
}

const ProgressStage = ({ operationProgress }: IProgressProps) => {
    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    return (
        <ProgressContains>
            <Progress
                percent={operationProgress.percent ?? 0}
                status="active"
                showInfo={true}
                strokeColor={twoColors}
            />
            <CSSTransition
                in={!!operationProgress.message}
                timeout={300}
                classNames="message"
                unmountOnExit
            >
                {state => (
                    <ProgressMessage $in={state === 'entered'}>
                        {operationProgress.message ?? ""}
                    </ProgressMessage>
                )}
            </CSSTransition>
        </ProgressContains>
    )
}

const Revisit: React.FC<RevisitStageProps> = ({ revisitComment, setRevisitComment}) => {
    const onRevisitTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRevisitComment(e.target.value);
    };
    
    return (
        <Form layout="horizontal">
            <Form.Item label="Revisit comment">
                <Space>
                    <TextArea 
                            placeholder="Enter revisit comment" 
                            rows={2}
                            allowClear
                            value={revisitComment} 
                            onChange={onRevisitTextChange} 
                        />
                </Space>
            </Form.Item>
        </Form>
    );
};

export const ActionFormModal = ({
    stage,
    item,
    users,
    open,
    operationProgress,
    revisitComment,
    handleModal,
    updateStage,
    setRevisitComment
}: IFormModalProps) => {
    const [user, setUser] = useState<number>(0);
    const [dueDate, setDueDate] = useState<string>('');
    const [selectedAction, setSelectedAction] = useState<string>('');
    
    const handleButtonClick = (clickAction: string, nextStage = "initial") => {
        setSelectedAction(clickAction);
        updateStage(nextStage);
        if (nextStage === "process") {
            handleModal(false, { status: clickAction, id: item.id, prev_status: item.status });
        }
    }

    const onOkClick = () => {
        if (stage === "assign") {
            updateStage("process");
            const selectedUser = users?.filter(ele => ele.id === user)[0];
            if (!selectedUser || !selectedUser?.id || !dueDate) {
                handleModal(true);
            }
            else {
                handleModal(false, {
                    status: selectedAction,
                    id: item.id,
                    prev_status: item.status,
                    ...((selectedUser?.id || dueDate) && {
                        assigned_to_1: selectedUser?.id,
                        assigned_due: dueDate,
                    })
                })
            }
        }
        else if (stage === "revisit") {
            updateStage("process");
            handleModal(false, {
                status: selectedAction,
                id: item.id,
                prev_status: item.status,
                revisit_comment: revisitComment
            })
        }
        else {
            handleModal(true);
        }
    }

    const onCancelClick = () => {
        handleModal(true);
    }

    var footer: JSX.Element[] = [];
    if (["initial", "assign","revisit"].includes(stage)) {
        footer.push(<Button key="back" onClick={onCancelClick} style={{ borderRadius: '15px' }}>
            Cancel
        </Button>)
    }
    if (stage !== "process") {
        footer.push(<Button key="submit" type="primary" disabled={stage == "initial"} onClick={onOkClick} style={{ borderRadius: '15px' }}>
            Confirm
        </Button>)
    }

    const title: string = {
        "initial": "Perform action",
        "assign": "Perform action",
        "process": "Processing...",
        "revisit": "Flag Revisit",
    }[stage] ?? "";
    
    return (
        <StyledModal
            open={open}
            onOk={onOkClick}
            onCancel={onCancelClick}
            title={title}
            centered
            footer={footer}
        >
            {stage === "initial" ? <InitialStage item={item} handleButtonClick={handleButtonClick} />
                : stage === "assign" ?
                    <AssignStage
                        users={users}
                        setDueDate={setDueDate}
                        setUser={setUser}
                    />
                    : stage === "process" ?
                        <ProgressStage operationProgress={operationProgress} />
                        : <Revisit revisitComment={revisitComment} setRevisitComment={setRevisitComment} />
            }
        </StyledModal>
    );
}