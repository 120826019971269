import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

interface CountCardProps {
    assignments: number;
}

export const CountCard: React.FC<CountCardProps> = ({ assignments }) => {
    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 240,
                width: '95%',
                p: 0,
                backgroundColor: '#111',
                color: 'white',
                borderRadius: '40px',
                transition: '0.3s',
                transform: 'scale(1)',
              }}>
            <CardContent sx={{ textAlign: 'center', width: '100%' }}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', color: '#FFDE59' }}>
                            {`${assignments}`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold', color: '#fff' }}>
                        Assignments Need Grading
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};