import React from 'react';
import { SubjectContentItem } from 'interfaces';

import ProgressChart from '../ProgressChart';
import { Col, Progress, Row, Table, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StyledTable } from './styled';

import "antd/dist/reset.css";

interface SubjectTableProps {
  tableData: SubjectContentItem[];
  subjectId: string;
  subjectName: string;
}

export const SubjectTable: React.FC<SubjectTableProps> = ({ tableData, subjectId, subjectName }) => {
  const navigate = useNavigate();
  
  const columns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      className: 'title-col black-background',
    },
    {
      title: '',
      dataIndex: 'progress',
      key: 'progress',
      className: 'black-background',
      render: (progress: number, record: SubjectContentItem) => (
        <div style={{ width: '50px', height: '50px' }}>
          <ProgressChart complete={record.complete} inProgress={record.in_progress} remaining={record.remaining} revisit={record.revisit} tooltipEnabled={false} />
        </div>
      ),
    },
    {
      title: 'Complete',
      dataIndex: 'complete',
      key: 'complete',
      className: 'complete-col black-background',
      sorter: (a: SubjectContentItem, b: SubjectContentItem) => (a.complete ?? 0) - (b.complete ?? 0),
      render: (complete: number, record: SubjectContentItem) => (
        <>{Math.round((complete ?? 0) / record.total * 100)}%</>
      ),
    },
    {
      title: 'In Progress',
      dataIndex: 'in_progress',
      key: 'in_progress',
      className: 'in-progress-col black-background',
      sorter: (a: SubjectContentItem, b: SubjectContentItem) => (a.in_progress ?? 0) - (b.in_progress ?? 0),
      render: (in_progress: number, record: SubjectContentItem) => (
        <>{Math.round((in_progress ?? 0) / record.total * 100)}%</>
      ),
    },
    {
      title: 'Revisit',
      dataIndex: 'revisit',
      key: 'revisit',
      className: 'revisit-col black-background',
      sorter: (a: SubjectContentItem, b: SubjectContentItem) => (a.revisit ?? 0) - (b.revisit ?? 0),
      render: (revisit: number) => (
        <>{revisit ?? 0}</>
      ),
    },
    {
      title: 'Needs Approval',
      dataIndex: 'needs_approval',
      key: 'needs_approval',
      className: 'needs-approval-col black-background',
      sorter: (a: SubjectContentItem, b: SubjectContentItem) => a.review - b.review,
      render: (needs_approval: number) => (
        <>{needs_approval ?? 0}</>
      ),
    },
    {
      title: 'Issues',
      dataIndex: 'issue',
      key: 'issue',
      className: 'issue-col black-background',
      sorter: (a: SubjectContentItem, b: SubjectContentItem) => a.issue - b.issue,
      render: (issue: number) => (
        <>{issue ?? 0}</>
      ),
    },
  ];


  return (
    <Col span={20}>
      <StyledTable
        columns={columns}
        dataSource={tableData}
        pagination={false}
        sortDirections={['descend', 'ascend']}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.content_type === 'past_papers') {
                navigate(`/dashboard/past-papers/${subjectId}`, {
                  state: {
                    id: record.id,
                    content_type: record.content_type,
                    content_type_name: record.title,
                    subject: subjectName,
                    subject_id: subjectId,
                  },
                });
              } else {
                navigate(`/dashboard/${subjectId}/content`, {
                  state: {
                    id: record.id,
                    content_type: record.content_type,
                    content_type_name: record.title,
                    subject: subjectName,
                    subject_id: subjectId,
                  },
                });
              }
            },
          };
        }}
      />
    </Col>
  );
};