import React from "react";
import { CustomTabsContainer, VerticalDivider, CustomTab, TabText } from './style';
import { ReviewContentOverview } from "interfaces";

interface CustomTabsProps {
    activeTab: number;
    setActiveTab: (index: number) => void;
    combinedData: {
        reviewData: ReviewContentOverview | null;
        issueData: ReviewContentOverview | null;
    };
    onTabChange: (index: number) => void;
}

export const CustomTabs: React.FC<CustomTabsProps> = ({ combinedData, activeTab, setActiveTab, onTabChange }) => {
    const {
        reviewData,
        issueData
    } = combinedData;

    const overdueCount = reviewData?.overdue ?? 0;
    const techCheckCount = reviewData?.inProgress ?? 0;
    const issuesOverdueCount = issueData?.overdue ?? 0;
    const issuesInProgressCount = issueData?.inProgress ?? 0;

    const handleTabClick = (index: number) => {
        setActiveTab(index);
        onTabChange(index);
    };

    return (
        <CustomTabsContainer>
            <CustomTab position="left" isActive={activeTab === 0} onClick={() => handleTabClick(0)}>
                <TabText isActive={activeTab === 0} fontSize='30px'>{techCheckCount} Tech Check</TabText> 
                <TabText isActive={activeTab === 0} overdue>{overdueCount} Overdue</TabText>
            </CustomTab>
            <VerticalDivider />
            <CustomTab position="right" isActive={activeTab === 1} onClick={() => handleTabClick(1)}>
                <TabText isActive={activeTab === 1} fontSize='30px'>{issuesInProgressCount} Issues</TabText> 
                <TabText isActive={activeTab === 1} overdue>{issuesOverdueCount} Overdue</TabText>
            </CustomTab>
        </CustomTabsContainer>
    );
};
