import React, { useState } from 'react';
import { Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { ContentItemDetail } from 'interfaces';
import { buildSheetUrl, getBoxClass, getStatusClass, statusSort } from 'utils/functions';
// import { ReasonModal } from '../ReasonModal';

import { StyledButton, StyledTable } from '../../dashboards.styled';
import "antd/dist/reset.css";

interface ContentTableProps {
  tableData: ContentItemDetail[];
  handleActionButtonClick: (item: ContentItemDetail) => void;
  handleRowIdClick: (row_id: number) => void;
}

const { Text } = Typography;

export const ContentTable: React.FC<ContentTableProps> = ({
  tableData,
  handleActionButtonClick,
  handleRowIdClick,
}) => {
  const columns = [
    {
      title: 'Level',
      dataIndex: 'section',
      key: 'section',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record, "team-leader"),
        },
      }),
      render: (text: string, record: any) => (
        record.capture_id && record.status !== "Complete" ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell cell-link"}>
              <span style={{ paddingLeft: '1rem' }}>{text}
              </span>
            </div>
          </Link>
          :
          <div className={"section-cell"}>
            <span style={{ paddingLeft: '1rem' }}>{text}
            </span>
          </div>
      ),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record, "team-leader"),
        },
      }),
      render: (text: string, record: any) => (
        <Link to={`/dashboard/department/${record.assigned_to_id}`}
          style={{ color: '#000' }}
          state={{ developer_name: text }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'assigned_due',
      key: 'assigned_due',
      sorter: (a: any, b: any) => {
        if (!(moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "Reassigned", "In Progress"].includes(a.status))) {
          return 1;
        }
        if (!(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "Reassigned", "In Progress"].includes(b.status))) {
          return -1;
        }
        return moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isBefore(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true)) ? -1 : 1;
      },
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record, "team-leader"),
        },
      }),
      render: (text: string, record: any) => (
        record.status === "Issue Reported" ? "" : text && record.status !== "Complete" ? moment(text, "DD/MM/YYYY").format("D MMM YYYY") : ""
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => statusSort(a.status, b.status),
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record, "team-leader"),
        },
        onClick: () => {
          if (["Issue Reported", "Revisit"].includes(record.status)) {
            var text = [];
            if (record.status === "Issue Reported") {
              if (record.issue_date) {
                text.push(<Text strong>{moment(record.issue_date, "DD/MM/YYYY").format("D MMM YYYY")}: </Text>)
              }
              text.push(<Text>{record.issue_reason}</Text>);
            }
            else if (record.revisit_comment){
              text.push(<Text>{record.revisit_comment}</Text>);
            }
            else{
              text.push(<Text>{"No reason provided."}</Text>);
            }
            handleRowIdClick(record.id);
          }
        }
      }),
      render: (text: string, record: any) => (
        <div className={getBoxClass(record)}>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record, "team-leader"),
        },
      }),
      render: (_: string, record: any) => (
        <StyledButton
          onClick={() => handleActionButtonClick(record)}
          type="primary"
          className={record.status === "Issue Reported" ? "disabled" : ""}
          disabled={record.status === "Issue Reported"}
        >
          Action
        </StyledButton>
      ),
    },
  ];

  return (
    <Col span={20}>
      <StyledTable
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
    </Col>
  )
}
