import React, { useEffect, useState } from "react";
import { useApiUrl, useList } from "@refinedev/core";
import { useLocation, useParams } from "react-router-dom";
import { Col, message, Row, Typography } from "antd";
import { axiosInstance } from "@refinedev/simple-rest";

import { IPaperActionObject, PaperItemDetail, Splitter } from "interfaces";
import { PapersTable, PaperActionFormModal } from "dashboards/components/admin-dashboard";
import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const PaperOverview: React.FC = () => {
    const apiUrl = useApiUrl();

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Past Paper Dashboard');
    }, []);

    const [version, setVersion] = useState(0);

    const { data } = useList<PaperItemDetail>({
        resource: `dashboard/admin/papers?version=${version}&`
    });

    const { data: userData } = useList<Splitter>({
        resource: `dashboard/admin/splitters`
    });

    const tableData = data?.data ?? [];
    const users = userData?.data ?? [];

    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<PaperItemDetail>({} as PaperItemDetail);
    const [messageApi, contextHolder] = message.useMessage();

    const handleActionButtonClick = (item: PaperItemDetail) => {
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
    }

    const handleModal = async (isCancel: boolean, action?: IPaperActionObject) => {
        if (!isCancel && action) {
            handleBeginOperation(action);
        }
        else {
            setModalStage("initial");
            setOpen(false);
        }
    }

    const handleBeginOperation = async (action: IPaperActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/admin/paper`, { paper: action }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                console.log(body)
                if (body.code === 200) {
                    messageApi.open({
                        type: 'success',
                        content: body.message
                    });
                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: body.message
                    });
                }
            }
            handleModalClose();
            setVersion(v => v + 1);
        } catch (error) {
            console.error(error);
            handleModalClose();
        }
    }

    const handleModalClose = async () => {
        setModalStage("initial");
        setOpen(false);
    }

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={24}>
                    <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Past Paper Dashboard</Title>
                </Col>
            </Row>
            <Row>
                <PapersTable
                    tableData={tableData}
                    handleActionButtonClick={handleActionButtonClick}
                />
                {open &&
                    <PaperActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                    />
                }
            </Row>
        </>
    )
};