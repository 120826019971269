import React, { useEffect } from "react";
import { useList } from "@refinedev/core";
import { useLocation } from "react-router-dom";
import { Col, Row, Typography } from "antd";

import { ToDoCard, DepartmentTable } from "dashboards/components/team-leader-dashboard";
import { DepartmentDeveloper, DepartmentDeveloperOverview, ToDoData } from "interfaces";

import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const DepartmentOverview: React.FC = () => {
    const location = useLocation();

    const { data } = useList({
        resource: `dashboard/team_leader/department`,
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: "dashboard/team_leader/todo",
    });

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Department Overview');
    }, []);

    const rawData = data?.data as DepartmentDeveloperOverview | undefined;
    const tableData = rawData?.details as DepartmentDeveloper[] | undefined ?? [];
    const department = rawData?.department as string | undefined ?? '';
    const toDo = toDoData?.data as ToDoData | undefined;

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{`${department} Department`}</Title>
                </Col>
                <Col span={4}>
                    <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
                </Col>
            </Row>
            <Row>
                <DepartmentTable tableData={tableData} />
            </Row>
        </>
    )
};