import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApiUrl, useList } from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";
import { Col, Row, Typography } from "antd";
import { DesktopOutlined } from "@ant-design/icons";

import { ActionFormModal, BreadCrumbModule, ToDoCard, ReasonModal, DeveloperTable } from "dashboards/components/team-leader-dashboard";
import { Developer, DeveloperContentItemDetail, IActionObject, LoggedIssue, OperationProgress, PaperItemDetail, SubjectDeveloper, SubjectDeveloperOverview, ToDoData } from "interfaces";
import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const DeveloperOverview: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const apiUrl = useApiUrl();

    const [version, setVersion] = useState(0);

    const { data, isLoading } = useList({
        resource: `dashboard/team_leader/department/${id}?version=${version}&`
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: "dashboard/team_leader/todo",
    });

    const { data : papersData} = useList<PaperItemDetail>({
        resource: `dashboard/admin/papers?version=${version}&`
    });

    const paperData = papersData?.data as PaperItemDetail[] | undefined ?? [];
    const rawData = data?.data as SubjectDeveloperOverview | undefined;
    const tableData = rawData?.details as DeveloperContentItemDetail[] | undefined ?? [];
    const subject_users = rawData?.users as SubjectDeveloper | undefined ?? {};
    const developer = rawData?.developer as string | undefined ?? '';
    const toDo = toDoData?.data as ToDoData | undefined;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${developer ?? "Developer"}`);
    }, []);

    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<DeveloperContentItemDetail>({} as DeveloperContentItemDetail);
    const [users, setUsers] = useState<Developer[]>([]);
    const [operationId, setOperationId] = useState<number>(0);
    const [operationProgress, setOperationProgress] = useState<OperationProgress>({} as OperationProgress);
    const [issueReason, setIssueReason] = useState<string>("");
    const [loggedIssue, setLoggedIssue] = useState<LoggedIssue[]>([]); 
    const [reasonModalVisible, setReasonModalVisible] = useState(false);

    const uniqueSubjects = Array.from(new Set(tableData.map(item => item.subject)));

    const matchedPapers = uniqueSubjects.reduce((acc, subject) => {
        const matchingPapers = paperData.filter(paper  => paper.subject === subject);
        if (matchingPapers.length > 0) {
            acc[subject] = matchingPapers;
        }
        return acc;
    }, {} as Record<string, PaperItemDetail[]>);

    const enrichedTableData = tableData.flatMap(item => {
        const enrichedData = [item];
    
        if (matchedPapers[item.subject]) {
            const transformedPapers = matchedPapers[item.subject].map(paper => ({
                id: paper.id,
                content_title: "Past Papers",
                level: paper.paper,
                assigned_due: paper.assigned_due ?? "",
                assigned_to: paper.assigned_to ?? "",
                status: paper.status as DeveloperContentItemDetail['status'],
                subject: paper.subject,
                capture_id: "",
                issue_reason: "",
                revisit_comment: ""
            }));
            enrichedData.push(...transformedPapers);
        }        
        return enrichedData;
    });
    
    const handleActionButtonClick = (subject_name: string, item: DeveloperContentItemDetail) => {
        if (subject_users && subject_name in subject_users) {
            setUsers(subject_users[subject_name]);
        }
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
        setIssueReason('');
    }

    const handleRowIdClick = async (row_id: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/team_leader/issue-report`, { row_id }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setLoggedIssue(data);
                setReasonModalVisible(true);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const handleModal = async (isCancel: boolean, action?: IActionObject) => {
        if (!isCancel && action) {
            handleBeginOperation(action);
        }
        else {
            setModalStage("initial");
            setOpen(false);
            setIssueReason('');
        }
    }

    const handleBeginOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/content`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getProgress = async () => {
        const response = await axiosInstance.get(`${apiUrl}/dashboard/content/${operationId}/progress`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        setOperationProgress(response.data);
        return response;
    }

    useEffect(() => {
        if (operationId === 0) return;

        var stayOpen = true;
        const intervalId = setInterval(async () => {
            if (!stayOpen) {
                if (location.state.content_type.includes("multi_write") && selectedItem.status === "Not Started") {
                    setModalStage("open_sheet");
                }
                else {
                    handleModalClose();
                    setOperationId(0);
                }

                setVersion(v => v + 1);
                clearInterval(intervalId);
            }
            else {
                try {
                    const response = await getProgress();
                    stayOpen = response.data.percent < 100;
                } catch (error) {
                    console.error(error);
                    setOperationId(0);
                    handleModalClose();
                    clearInterval(intervalId);
                }
            }

        }, 1500);
    }, [operationId])

    const handleModalClose = async () => {
        setModalStage("initial");
        setOperationId(0);
        setOperationProgress({} as OperationProgress);
        setOpen(false);
        setIssueReason('');
    }

    const closeModal = () => {
        setReasonModalVisible(false);
        setIssueReason('');
        setLoggedIssue([]);
    }

    const breadCrumbItems = [
        { href: "/dashboard", title: <><DesktopOutlined /><span>Dashboard</span></> },
        { href: `/dashboard/department`, title: "Department" },
    ];

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{developer || ""}</Title>
                    </Col>
                    <Col span={24} style={{ marginLeft: '10px', marginTop: '20px' }}>
                        <BreadCrumbModule items={breadCrumbItems} />
                    </Col>
                </Col>
                <Col span={4}>
                    <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
                </Col>
            </Row>
            <Row>
                <DeveloperTable
                    tableData={enrichedTableData}
                    handleActionButtonClick={handleActionButtonClick}
                    handleRowIdClick={handleRowIdClick}
                />
                {reasonModalVisible &&
                    <ReasonModal
                        visible={reasonModalVisible}
                        loggedIssue={loggedIssue} 
                        onClose={closeModal}
                    />
                }
                {open &&
                    <ActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        operationProgress={operationProgress}
                        issueReason={issueReason}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                        setIssueReason={setIssueReason}
                    />
                }
            </Row>
        </>
    )
};