import { Button, Table } from "antd";
import { styled } from "styled-components";

export const StyledTable = styled(Table) <{ $padding?: boolean; borderRadius?: boolean; paddingLeft?: boolean; borderTopLeftRadius?: boolean; borderBottomRightRadius?: boolean; borderTopRightRadius?: boolean; borderBottomLeftRadius?: boolean }>`
    table {
        border-collapse: separate !important;
        border-spacing: 0 0.5rem !important;
    }

    .ant-table {
        background: transparent !important;
        font-size: 20px;
        font-weight: bold;
    }

    .ant-table-thead > tr > th {
        background: #000 !important; 
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-top: 1px solid #d9d9d9;
        border-bottom: none;
        padding: 8px 16px;
        text-align: center;
        vertical-align: middle;
    }

    .ant-table-thead>tr>th:first-child {
        border-top-left-radius: 35px !important;
        border-bottom-left-radius: 35px !important;
    }

    .ant-table-thead>tr>th:last-child {
        border-top-right-radius: 35px !important;
        border-bottom-right-radius: 35px !important;
    }
    .ant-table-tbody > tr > td {
        text-align: center;
        vertical-align: middle;
    }

    .ant-table-tbody > tr > td:first-child {
        text-align: left;
    }

    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 35px !important;
        border-bottom-left-radius: 35px !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 35px !important;
        border-bottom-right-radius: 35px !important;
    }

    .ant-table-filter-trigger {
        color: #fff;
        transition: color 0.3s ease;
    }

    .ant-table-filter-trigger:hover {
        color: #c1c1c1;
    }

    .ant-table-column-sorter {
        color: #fff;
        transition: color 0.3s ease;
    }

    .ant-table-column-sorter:hover {
        color: #c1c1c1 !important;
    }

    .section-cell {
        text-align: center;
        background-color: #fff;
        border-radius: 15px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .cell-link:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }

    .box {
        background-color: #fff;
        border-radius: 15px;
    }

    .overdue {
        color: #FF3131;
    }

    .issue {
        color: #FF5757;
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .revisit {
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .issue:hover, .revisit:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }

    .reassigned {
        color: #CB6CE6;
    }

    .middle-cell {
        border-radius: ${props => props.borderRadius ? '0px' : '15px'}; /* Apply border radius based on props */
        padding-left: ${props => props.$padding ? '10px' : '0'};
        padding-right: ${props => props.$padding ? '10px' : '0'};
    }

    .ant-table-tbody tr td:first-child, .ant-table-tbody tr td:nth-child(2), .ant-table-tbody tr td:last-child {
        padding-right: ${props => props.$padding && '0'};
    }

    .ant-table-tbody tr td:nth-child(2), .ant-table-tbody tr td:nth-child(3), .ant-table-tbody tr td:last-child {
        padding-left: ${props => props.$padding && '0'};
    }

    .ant-table-tbody tr td:first-child .section-cell {
        border-radius: ${props => props.borderRadius ? '20px 0px 0px 20px' : '15px'};
    }

    .ant-table-tbody tr td:nth-child(3) .section-cell {
        border-radius: ${props => props.borderRadius ? '0px 20px 20px 0px' : '15px'};
    }    

    .hover-effect {
        transform: translateY(-5px);
    }
`;


export const StyledButton = styled(Button)`
    &&.ant-btn {
        border-radius: 15px;
        background-color: #fff;
        color: #000;
        font-weight: bold;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    &&.ant-btn:hover {
        color: #000;
        background-color: #fff;
        transform: translateY(-3px);
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }
`