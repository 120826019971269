import {
  AuthBindings,
  Refine,
  HttpError,
} from "@refinedev/core";
import {
  Layout,
  notificationProvider,
  ErrorComponent,
  Breadcrumb
} from "@refinedev/antd";
import dataProvider, { axiosInstance } from "@refinedev/simple-rest";
import routerProvider, { CatchAllNavigate } from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { DesktopOutlined, FileTextOutlined, FolderOpenOutlined, OrderedListOutlined, SlidersOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";

import {
  AssignmentCreate,
  AssignmentEdit,
  AssignmentList,
  CourseListHS,
  CourseListAB,
  CourseSingle,
  GeneratePDF,
  PaperSplitterView,
  PaperList,
  StudentList,
  SubjectOverview,
  ContentOverview,
  DepartmentOverview,
  DeveloperOverview,
  PaperOverview,
  DashboardOverview,
  Callback,
  LoginPage,
  withMaintenanceCheck,
  DevDashboard,
  TechnicalDashboard,
  PastPapersOverview,
  SubjectPapers,
  AssignmentPapers
} from "pages";

import { CustomSider, RoleAuthenticated } from "components";
import { API_URL } from "utils/constants";
import { AssignmentDataProvider } from "contexts/AssignmentData";
import { QuestionsDataProvider } from "contexts/QuestionsData";

import "@refinedev/antd/dist/reset.css";
import { TitleProvider } from "contexts/TitleContext";

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

export const authProvider: AuthBindings = {
  login: async ({ token, roles }) => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("roles", JSON.stringify(roles))
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (roles.includes("tutor")) {
        return {
          success: true,
          redirectTo: "/courses"
        }
      }
      else if (roles.includes("observer")) {
        return {
          success: true,
          redirectTo: "/students"
        }
      }
      else if (roles.includes("team-leader") || roles.includes("admin")) {
        return {
          success: true,
          redirectTo: "/dashboard"
        }
      }
      else if (roles.includes("developer")) {
        return {
          success: true,
          redirectTo: "/tasks"
        }
      }
      else {
        return {
          success: true,
          redirectTo: "/papers"
        }
      }
    }
    return {
      success: false,
      redirectTo: "/login",
      error: new Error("Invalid token")
    }
  },
  logout: async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error: Error) => {
    return {
      authenticated: false,
      logout: true,
      redirectTo: "/login",
      error: new Error(error.message),
    };

  },
  check: async () => {
    const token = localStorage.getItem("token");
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (token) {
      return {
        authenticated: true
      }
    }

    return {
      authenticated: false,
      error: new Error("Unauthenticated"),
      logout: true,
      redirectTo: "/login",
    };

  },
  getIdentity: async () => {
    const roles = localStorage.getItem("roles");
    try {
      const roles_list = JSON.parse(roles || "");
      if (roles_list.includes("observer")
        || roles_list.includes("tutor")
        || roles_list.includes("splitter")
        || roles_list.includes("team-leader")
        || roles_list.includes("developer")
        || roles_list.includes("admin")) {
        return roles_list;
      }
    }
    catch (e) {
      return null;
    }
    return null;
  }
};

function App() {
  const MaintenanceWrappedOutlet = withMaintenanceCheck(Outlet);
  return (
    <BrowserRouter>
      <TitleProvider>
        <AssignmentDataProvider>
          <QuestionsDataProvider>
            <Refine
              notificationProvider={notificationProvider}
              routerProvider={routerProvider}
              dataProvider={dataProvider(API_URL, axiosInstance)}
              authProvider={authProvider}
              resources={[
                {
                  name: "courses",
                  list: "/courses",
                  meta: {
                    icon: <TeamOutlined />,
                    label: "Courses",
                    hide: false,
                  }
                },
                {
                  name: "papers",
                  list: "/papers",
                  identifier: "papers",
                  meta: {
                    icon: <FileTextOutlined />,
                    label: "Papers",
                    hide: false,
                  }
                },
                {
                  name: "dashboard",
                  list: "/dashboard",
                  meta: {
                    icon: <DesktopOutlined />,
                    label: "Dashboard",
                    hide: false,
                  }
                },
                {
                  name: "management",
                  meta: {
                    icon: <SlidersOutlined />,
                    label: "Management",
                    hide: false,
                  }
                },
                {
                  name: "technical",
                  list: "/dashboard/admin/technical",
                  identifier: "admin/technical",
                  meta: {
                    parent: "management",
                    label: "Technical Review",
                    hide: false,
                  }
                },
                {
                  name: "papers",
                  list: "/dashboard/admin/papers",
                  identifier: "admin/papers",
                  meta: {
                    parent: "management",
                    label: "Paper Management",
                    hide: false,
                  }
                },
                {
                  name: "subject-papers",
                  list: "/dashboard/admin/subject-papers",
                  identifier: "admin/subject-papers",
                  meta: {
                    parent: "management",
                    label: "Subject Papers",
                    hide: false,
                  }
                },
                {
                  name: "ep-papers",
                  list: "/dashboard/assignments/papers",
                  identifier: "assignments/papers",
                  meta: {
                    icon: <FolderOpenOutlined />,
                    label: "EPF Papers",
                    hide: false,
                  }
                },
                {
                  name: "tasks",
                  list: "/tasks",
                  meta: {
                    icon: <OrderedListOutlined />,
                    label: "Tasks",
                    hide: false,
                  }
                },
              ]}
              options={{
                syncWithLocation: true,
                breadcrumb: <Breadcrumb breadcrumbProps={{
                  items: [
                    { title: <><UserOutlined /><span>Students</span></>, href: "" },
                  ]
                }} />
              }}
            >
              <Routes>
                <Route
                  path="/students"
                  element={
                    <RoleAuthenticated role={"observer"} fallback={<CatchAllNavigate to="/login" />}>
                      <Layout Sider={CustomSider}>
                        <Outlet />
                      </Layout>
                    </RoleAuthenticated>
                  }>
                  <Route index element={<StudentList />} />
                  <Route path=":id" element={<CourseListHS />} />
                  <Route path=":id/courses/:course_id" element={<AssignmentList />} />
                  <Route path=":id/courses/:course_id/assignments/:assignment_id" element={<AssignmentEdit />} />
                </Route>

                <Route
                  path="/courses"
                  element={
                    <RoleAuthenticated roles={["tutor", "developer", "team-leader"]} fallback={<CatchAllNavigate to="/login" />}>
                      <Layout Sider={CustomSider}>
                        <Outlet />
                      </Layout>
                    </RoleAuthenticated>
                  }>
                  <Route index element={<CourseListAB />} />
                  <Route path=":id" element={<CourseSingle />} />
                  <Route path=":id/assignments/create" element={<AssignmentCreate />} />
                  <Route path=":id/assignments/create/questions" element={<GeneratePDF />} />
                </Route>

                <Route
                  path="/papers"
                  element={
                    <RoleAuthenticated roles={["splitter", "team-leader"]} fallback={<CatchAllNavigate to="/login" />}>
                      <Layout Sider={CustomSider}>
                        <Outlet />
                      </Layout>
                    </RoleAuthenticated>
                  }>
                  <Route index element={<PaperList />} />
                  <Route path=":paper" element={<PaperSplitterView />} />
                </Route>

                <Route path="/dashboard">
                  <Route
                    path="admin"
                    element={
                      <RoleAuthenticated role={"admin"} fallback={<CatchAllNavigate to="/login" />}>
                        <Layout Sider={CustomSider}>
                          <MaintenanceWrappedOutlet />
                        </Layout>
                      </RoleAuthenticated>
                    }>
                    <Route path="papers" element={<PaperOverview />} />
                    <Route path="technical" element={<TechnicalDashboard />} />
                    <Route path="subject-papers" element={<SubjectPapers />} />
                  </Route>

                  <Route
                    path="assignments"
                    element={
                      <RoleAuthenticated roles={["team-leader", "developer", "admin"]} fallback={<CatchAllNavigate to="/login" />}>
                        <Layout Sider={CustomSider}>
                          <MaintenanceWrappedOutlet />
                        </Layout>
                      </RoleAuthenticated>
                    }>
                    <Route path="papers" element={<AssignmentPapers />} />
                  </Route>

                  <Route
                    path="*"
                    element={
                      <RoleAuthenticated role={"team-leader"} fallback={<CatchAllNavigate to="/login" />}>
                        <Layout Sider={CustomSider}>
                          <MaintenanceWrappedOutlet />
                        </Layout>
                      </RoleAuthenticated>
                    }>
                    <Route index element={<DashboardOverview />} />
                    <Route path=":id" element={<SubjectOverview />} />
                    <Route path=":id/content" element={<ContentOverview />} />
                    <Route path="department" element={<DepartmentOverview />} />
                    <Route path="department/:id" element={<DeveloperOverview />} />
                    <Route path="past-papers/:id" element={<PastPapersOverview />} />
                  </Route>
                </Route>

                <Route
                  path="/tasks"
                  element={
                    <RoleAuthenticated roles={["team-leader", "developer", "admin"]} fallback={<CatchAllNavigate to="/login" />}>
                      <Layout Sider={CustomSider}>
                        <MaintenanceWrappedOutlet />
                      </Layout>
                    </RoleAuthenticated>
                  }>
                  <Route index element={<DevDashboard />} />
                </Route>

                <Route
                  element={
                    <Layout Sider={CustomSider}>
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route index element={<Navigate to={"/login"} />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/auth/callback" element={<Callback />} />
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
            </Refine>
          </QuestionsDataProvider>
        </AssignmentDataProvider>
      </TitleProvider>
    </BrowserRouter>
  );
}

export default App;
