import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HttpError, useList, useLogout } from "@refinedev/core";
import { Col, Row, Spin, Typography} from "antd";

import { extractSimplifiedName } from "utils/functions";

import { CountCard, CoursesGrid } from "hs-marking/components";
import { ICourse, ICourseAssignments, IStudent } from "interfaces";

import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;
const TagColor = (value: number) => value >= 65 ? "success" : value >= 40 ? "warning" : "error";

export const CourseList: React.FC = () => {
    const [assignments, setAssignments] = useState(0);
    const {id} = useParams();
    const {mutate: logout} = useLogout();
    const { data, isLoading } = useList<ICourseAssignments, HttpError>({
        resource: `students/${id}`,
        errorNotification: () => {
            logout();
            return {
                message: "Please login again",
                description: "Session expired",
                type: "error"
            }
        }
    });
    const student = data?.data as unknown as IStudent;
    const student_name = student?.name;
    const courses: ICourse[] = student?.courses;

    const modifiedCardsData = courses?.map((course) => ({
      ...course,
      name: extractSimplifiedName(course.name),
    }));

    const { setTitle } = useTitle();
    useEffect(() => {
      setTitle(`${student_name ?? "View student"}`);
      if (student && student.courses) {
        const totalAssignments = student.courses.reduce((total, course) => {
          return total + course.assignments;
        }, 0);
        setAssignments(totalAssignments);
      }
    }, [student]);
      
    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                {!isLoading  && (
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{ student_name }</Title>
                    </Col>
                )}
                </Col>
                <Col span={4}>
                    <CountCard assignments= {assignments}/>
                </Col>
            </Row>
            <Row>
            {isLoading  ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh',
                    width: '100vw'
                }}>
                    <Spin size="large" />
                    <p style={{ marginTop: '16px' }}>Loading Courses...</p>
                </div>
            ):(
                <CoursesGrid cardsData={modifiedCardsData}/>
            )}
            </Row>
        </>
    )
}