import React, { useState } from 'react';
import { ReviewContentItemDetail } from 'interfaces';

import { Col } from 'antd';

import "antd/dist/reset.css";
import { Link } from 'react-router-dom';
import { buildSheetUrl, getStatusClass } from 'utils/functions';
import moment from 'moment';
import { SortOrder } from 'antd/es/table/interface';
import { StyledButton, StyledTable } from '../../dashboards.styled';

interface ReviewTableProps {
  tableData: ReviewContentItemDetail[];
  handleActionButtonClick: (record : string, item: ReviewContentItemDetail) => void;
  handleContentLog: (row_id: number) => void;
}

export const ReviewTable: React.FC<ReviewTableProps> = ({
  tableData,
  handleActionButtonClick,
  handleContentLog
}) => {
  const filteredData = tableData.filter(item => item.status !== 'Issue Reported');
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [viewLog, setViewLog] = useState<boolean | null>(null);
  const createFilter = (dataIndex: string) => {
    const uniqueValues = Array.from(new Set(tableData.map((item: any) => item[dataIndex])));
    return uniqueValues.filter(value => value != null).map(value => ({ text: value.toString(), value: value }));
  };

  const columns = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a: any, b: any) => {
        const getNumericLevel = (level: string) => parseInt(level.replace(/\D/g, ''), 10);
        return getNumericLevel(a.level) - getNumericLevel(b.level);
      },
      filters: createFilter('level'),
      onFilter: (value: any, record: any) => record.level === value,
      filterSearch: true,
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },
    {
      title: 'Content Type',
      dataIndex: 'content_type',
      key: 'content_type',
      sorter: (a: any, b: any) => a.content_type.localeCompare(b.content_type),
      filters: createFilter('content_type'),
      onFilter: (value: any, record: any) => record.content_type === value,
      filterSearch: true,
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell middle-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell middle-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a: any, b: any) => a.subject.localeCompare(b.subject),
      filters: createFilter('subject'),
      onFilter: (value: any, record: any) => record.subject === value,
      filterSearch: true,
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },
    {
      title: 'Assigned User',
      dataIndex: 'assigned_user',
      key: 'assigned_user',
      sorter: (a: any, b: any) => {
        const userA = a.assigned_user || '';
        const userB = b.assigned_user || '';
        return userA.localeCompare(userB);
      },
      filters: createFilter('assigned_user'),
      onFilter: (value: any, record: any) => record.assigned_user === value,
      filterSearch: true,
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
    },    
    {
      title: 'Due Date',
      dataIndex: 'action_date',
      key: 'action_date',
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: any, b: any) => {
        if (!(moment(a.action_date, ["D/M/YYYY", "D/MM/YYYY"], true).isValid())) {
          return 1;
        }
        if (!(moment(b.action_date, ["D/M/YYYY", "D/MM/YYYY"], true).isValid())) {
          return -1;
        }
        return moment(a.action_date, ["D/M/YYYY", "D/MM/YYYY"], true).isBefore(moment(b.action_date, ["D/M/YYYY", "D/MM/YYYY"], true)) ? -1 : 1;
      },
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => (
        text ? moment(text, "DD/MM/YYYY").format("D MMM YYYY") : ""
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (_: string, record: any, rowIndex: any) => (
        <div style={{ position: 'relative'}}>
          <StyledButton
            onClick={() => handleActionButtonClick(record.subject, record)}
            type="primary"
          >
            Action
          </StyledButton>
          <div>
            {hoveredRowIndex === rowIndex && (
              <div 
                onClick={(e) => {
                  e.stopPropagation();
                  handleContentLog(record.id);
                }}
                onMouseEnter={ () => setViewLog(true)}
                onMouseLeave={ () => setViewLog(false)}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: viewLog ? "85px" : "30px",
                  transform: "translateY(-50%)",
                  backgroundColor: "#f0f0f0",
                  padding: "16px 14px 16px 40px",
                  borderRadius: "0px 40px 40px 0px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  transition: "left 0.5s ease",
                  zIndex: -1,
                }}
              >
                View Log
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleMouseEnterConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.add('hover-effect');
    });
  };

  const handleMouseLeaveConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.remove('hover-effect');
    });
  };

  return (
    <Col span={20}>
      <StyledTable
        borderBottomRightRadius
        borderTopRightRadius
        borderBottomLeftRadius
        borderTopLeftRadius
        $padding
        borderRadius
        paddingLeft
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        onRow={(record, rowIndex) => {
          return {
              onMouseEnter: () => {
                if (rowIndex !== undefined) {
                  setHoveredRowIndex(rowIndex);
                }
              },
              onMouseLeave: () => {
                setHoveredRowIndex(null);
              },
              style: {
                position:'relative',
                zIndex: 1,
              },
          };
        }}
      />
    </Col>
  )
}
