import React, { useState, useEffect } from "react";
import {
    ITreeMenu,
    CanAccess,
    useTranslate,
    useLogout,
    useMenu,
    useWarnAboutChange,
    useIsAuthenticated,
    useGetIdentity,
    useApiUrl,
} from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";
import { Link, useLocation } from "react-router-dom";
import { Sider } from "@refinedev/antd";
import { Layout as AntdLayout, Menu, Grid } from "antd";
import {
    DashboardOutlined,
    LogoutOutlined,
    TeamOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { antLayoutSider, antLayoutSiderMobile, logoStyle } from "./styles";

import logo from "../../assets/logo-150.png";
import saintsburg from "../../assets/saintsburg-transparant.png";
import { extractSimplifiedName } from "utils/functions";

export const CustomSider: typeof Sider = ({ render }) => {
    const apiUrl = useApiUrl();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [studentsData, setStudentsData] = useState<any | null>(null);
    const [openKeys, setOpenKeys] = useState<string[]>([]); 
    
    const { data: isAuthenticated } = useIsAuthenticated();
    const { warnWhen, setWarnWhen } = useWarnAboutChange();
    const { mutate: mutateLogout } = useLogout();
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const { SubMenu } = Menu;

    const { data: identity } = useGetIdentity();
    const identity_roles = identity as string[];

    const breakpoint = Grid.useBreakpoint();
    const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`${apiUrl}/students`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setStudentsData(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [apiUrl]);

    const details = studentsData as any | undefined;

    const renderCourses = (studentId: string) => {
        const student = details?.students.find((student: any) => student.id === studentId);
        if (!student) return null;

        return (
            <>
                {student.courses.map((course: any) => (
                    <Menu.Item key={`/students/${studentId}/courses/${course.id}`}>
                        <Link to={`/students/${studentId}/courses/${course.id}`}>
                            {extractSimplifiedName(course.name)}
                        </Link>
                    </Menu.Item>
                ))}
            </>
        );
    };

    const handleOpenChange = (keys: string[]) => {
      const parentKey = "students";
      const isParentOpen = keys.includes(parentKey);

      const filteredKeys = keys.filter((key) => key !== parentKey);

      const limitedKeys = filteredKeys.slice(-1);
      if (!isParentOpen) {
        setOpenKeys(limitedKeys);
      } else {
        setOpenKeys([parentKey, ...limitedKeys]);
      }
    };

    const renderStudentsMenu = () => {
        if (!details) return null;

        return (
            <SubMenu
                key="students"
                icon={<TeamOutlined />}
                title={<Link to="/students" style={{ color: 'white', textDecoration: 'none' }}>Students</Link>}
            >
                {details.students.map((student: any) => (
                    <SubMenu
                        key={`/students/${student.id}`}
                        title={<Link to={`/students/${student.id}`} style={{ color: 'white', textDecoration: 'none' }}>{student.name}</Link>}
                    >
                        {renderCourses(student.id)}
                    </SubMenu>
                ))}
            </SubMenu>
        );
    };

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        const roles: any = {
            "students": ["observer"],
            "courses": ["admin", "tutor"],
            "papers": ["splitter"],
            "dashboard": ["team-leader"],
            "management": ["admin"],
            "admin/technical": ["admin"],
            "admin/papers": ["admin"],
            "admin/subject-papers": ["admin"],
            "assignments/papers": ["admin", "team-leader", "developer"],
            "tasks": ["admin", "team-leader", "developer"],
        };
        return tree.map((item: ITreeMenu) => {
            const { identifier, name, route, children, meta } = item;

            if (!identity_roles || !identity_roles.length || !identity_roles.some(role => roles[(identifier ?? name ?? "").toLowerCase()].includes(role))) return <></>;

            if (children.length > 0) {
                return (
                    <SubMenu
                        key={route}
                        icon={meta?.icon ?? <UnorderedListOutlined />}
                        title={meta?.label}
                    >
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selectedKey;
            const isRoute = !(
                meta?.parent !== undefined &&
                children.length === 0
            );
            return (
                <CanAccess
                    key={route}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{ resource: item }}
                >
                    <Menu.Item
                        key={route}
                        style={{
                            fontWeight: isSelected ? "bold" : "normal",
                        }}
                        icon={meta?.icon ?? (isRoute && <UnorderedListOutlined />)}
                    >
                        {route ? <Link to={route}>{meta?.label}</Link> : meta?.label}
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            );
        });
    };

    const handleLogout = () => {
        if (warnWhen) {
            const confirm = window.confirm(
                translate(
                    "warnWhenUnsavedChanges",
                    "Are you sure you want to leave? You have unsaved changes.",
                ),
            );

            if (confirm) {
                setWarnWhen(false);
                mutateLogout();
            }
        } else {
            mutateLogout();
        }
    };

    const loginLogout = isAuthenticated?.authenticated ? (
        <Menu.Item
            key="logout"
            onClick={handleLogout}
            icon={<LogoutOutlined />}
        >
            {translate("buttons.logout", "Logout")}
        </Menu.Item>
    ) : (
        <Menu.Item
            key="login"
            icon={<LogoutOutlined />}
        >
            <Link to="/login">{translate("buttons.login", "Login")}</Link>
        </Menu.Item>
    );

    const items = renderTreeView(menuItems, currentPath);

    const renderSider = () => {
        if (render) {
            return render({
                dashboard: null,
                items,
                logout: loginLogout,
                collapsed,
            });
        }
        return (
            <>
                {renderStudentsMenu()}
                {items}
                {loginLogout}
            </>
        );
    };

    return (
        <AntdLayout.Sider
            collapsible
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="lg"
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            style={isMobile ? antLayoutSiderMobile : antLayoutSider}
        >
            <Link to="/">
                {collapsed ? (
                    <img
                        src={logo}
                        alt="Saintsburg"
                        style={logoStyle}
                    />
                ) : (
                    <img
                        src={saintsburg}
                        alt="Saintsburg"
                        style={logoStyle}
                    />
                )}
            </Link>
            <Menu
                theme="dark"
                defaultOpenKeys={defaultOpenKeys}
                selectedKeys={[currentPath]}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={handleOpenChange}
                onClick={() => {
                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }
                }}
            >
                {renderSider()}
            </Menu>
        </AntdLayout.Sider>
    );
};