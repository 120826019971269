import React, { useState } from "react";
import { Avatar, Card, Col, List, Radio, Row } from "antd";
import { Link } from "react-router-dom";
import { ICourse } from "interfaces";
import { generateRandomColor } from "utils/functions";

import "./coursegrid.css";

enum ViewMode {
    Grid,
    List
}

export const CourseGrid: React.FC<{ courses: ICourse[] }> = ({ courses }) => {
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Grid);

    const getInitials = (name: string) => {
        return name
            .split(" ")
            .map((word) => word.charAt(0))
            .join("");
    };

    const courseCard = (course: ICourse) => (
        <Col key={course.id} xs={24} sm={12} md={8} lg={6}>
            <Link to={`/courses/${course.id}/assignments/create?type=quest`}>
                <Card
                    hoverable
                    style={{
                        marginBottom: 16,
                        backgroundColor: generateRandomColor(course.name),
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 150,
                        }}
                    >
                        <Avatar style={{ backgroundColor: "white", color: "black" }} size={"large"}>
                            {getInitials(course.name)}
                        </Avatar>
                    </div>
                    <div className="course-name">{course.name}</div>
                </Card>
            </Link>
        </Col>
    );

    const courseListItem = (course: ICourse) => (
        <List.Item key={course.id}>
            <Link to={`/courses/${course.id}/assignments/create?type=quest`}>
                <Avatar style={{ backgroundColor: generateRandomColor(course.name), marginRight: 8 }}>
                    {getInitials(course.name)}
                </Avatar>
                {course.name}
            </Link>
        </List.Item >
    );

    return (
        <div>
            <Radio.Group
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                style={{ marginBottom: 16 }}
            >
                <Radio.Button value={ViewMode.Grid}>Grid</Radio.Button>
                <Radio.Button value={ViewMode.List}>List</Radio.Button>
            </Radio.Group>

            {viewMode === ViewMode.Grid ? (
                <Row gutter={16} className="coursegrid-row">
                    {courses.map(courseCard)}
                </Row>
            ) : (
                <List
                    itemLayout="horizontal"
                    dataSource={courses}
                    renderItem={courseListItem}
                    className="coursegrid-list"
                />
            )}
        </div>
    );
};
