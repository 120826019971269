
import { Button, Col, ConfigProvider, Divider, Form, Input, InputRef, Progress, ProgressProps, Row, Select, Space } from "antd";
import { IdcardOutlined, WarningOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import { CSSTransition } from 'react-transition-group';
import { axiosInstance } from "@refinedev/simple-rest";

import { ContentItemDetail, Developer, DeveloperContentItemDetail, IActionObject, OperationProgress } from "interfaces";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FormButton, ProgressContains, ProgressMessage, StyledModal } from "../../actionmodal.styled";
import { useApiUrl } from "@refinedev/core";

dayjs.extend(customParseFormat);

interface IFormModalProps {
    stage: string;
    item: ContentItemDetail | DeveloperContentItemDetail;
    users: Developer[];
    open: boolean;
    operationProgress: OperationProgress;
    issueReason: string;
    time: string;
    updateStage: React.Dispatch<React.SetStateAction<string>>;
    handleModal: (isCancel: boolean, action?: IActionObject) => void;
    setIssueReason: React.Dispatch<React.SetStateAction<string>>;
    setTime: React.Dispatch<React.SetStateAction<string>>;
}

interface IInitialStageProps {
    item: ContentItemDetail | DeveloperContentItemDetail;
    handleButtonClick: (clickAction: string, nextStage?: string) => void;
}

interface IIssueStageProps {
    issueReason: string;
    setIssueReason: React.Dispatch<React.SetStateAction<string>>;
}

interface AddTimeProps {
    time: string;
    setTime: React.Dispatch<React.SetStateAction<string>>;
    setIsTimeLogged: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IProgressProps {
    operationProgress: OperationProgress;
}

interface IColorButtonProps {
    btnColor: string;
    btnNode: React.ReactNode;
}

interface IMessage {
    text: string;
    color: string;
}

const issue_reasons = [
    "Broken sheet - value errors etc.",
    "Cannot access sheet",
    "Categories are wrong",
    "Expeditions are wrong",
    "Levels are wrong",
    "Textbooks are wrong",
    "Wrong sheet assigned",
]


const ColorButton = ({ btnColor, btnNode }: IColorButtonProps) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: btnColor,
                },
            }}
        >
            {btnNode}
        </ConfigProvider>
    )

}
const InitialStage = ({ item, handleButtonClick }: IInitialStageProps) => {
    const disabledRules = {
        "Complete": ['Complete', 'Not Started', 'Not Needed'].includes(item.status),
        "Issue": ['Issue Reported', 'Not Started', 'Not Needed'].includes(item.status),
    }
    return (
        <Row gutter={[8, 16]}>
            <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <ColorButton
                    btnColor={'#00b96b'}
                    btnNode={<FormButton icon={<WarningOutlined />}
                        onClick={() => handleButtonClick('Issue', 'report_issue')}
                        type="primary"
                        style={{ width: '60%' }}
                        disabled={disabledRules['Issue']}
                        danger
                    >
                        Flag issue
                    </FormButton>}
                />
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'start' }}>
                <ColorButton
                    btnColor={'#00b96b'}
                    btnNode={<FormButton
                        icon={<CheckOutlined />}
                        onClick={() => handleButtonClick('Complete', 'add_time')}
                        type="primary"
                        style={{ width: '60%' }}
                        disabled={disabledRules['Complete']}
                    >
                        Mark complete
                    </FormButton>}
                />
            </Col>
        </Row >
    )

}

const IssueStage = ({ issueReason, setIssueReason }: IIssueStageProps) => {
    const [items, setItems] = useState<string[]>(issue_reasons);
    const inputRef = useRef<InputRef>(null);
    var index = 0;

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        setItems([...items, issueReason || `New item ${index++}`]);
        setIssueReason('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const onIssueReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIssueReason(e.target.value);
    }

    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Select issue">
                <Select onChange={(e) => setIssueReason(e)}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                                <Input
                                    placeholder="Other reason"
                                    ref={inputRef}
                                    value={issueReason}
                                    onChange={onIssueReasonChange}
                                    onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                    Add item
                                </Button>
                            </Space>
                        </>
                    )}
                    options={items.map((item) => ({ label: item, value: item }))} />
            </Form.Item>
        </Form>
    )
}

const AddTime = ({ time, setTime, setIsTimeLogged, isTimeLogged, setMessage }: AddTimeProps & { isTimeLogged: boolean, setMessage: React.Dispatch<React.SetStateAction<IMessage | null>> }) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (/^\d{0,3}$/.test(inputValue)) {
            setTime(inputValue);
        }
    };

    const handleClick = () => {
        if (time) {
            setIsTimeLogged(true);
            setMessage({ text: "Time logged successfully!", color: "green" });
        } else {
            setMessage({ text: "Please enter valid time.", color: "red" });
        }
    };

    return (
        <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Time Spent (min)">
                <Space style={{ padding: '0 8px 4px' }}>
                    <Input placeholder="Add time in minutes" value={time} onChange={handleInputChange} />
                    <Button type="primary" onClick={handleClick} style={{ borderRadius: '15px' }} disabled={isTimeLogged}>
                        Log Time
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

const ProgressStage = ({ operationProgress }: IProgressProps) => {
    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    return (
        <ProgressContains>
            <Progress
                percent={operationProgress.percent ?? 0}
                status="active"
                showInfo={true}
                strokeColor={twoColors}
            />
            <CSSTransition
                in={!!operationProgress.message}
                timeout={300}
                classNames="message"
                unmountOnExit
            >
                {state => (
                    <ProgressMessage $in={state === 'entered'}>
                        {operationProgress.message ?? ""}
                    </ProgressMessage>
                )}
            </CSSTransition>
        </ProgressContains>
    )
}

export const ActionFormModal = ({
    stage,
    item,
    users,
    open,
    operationProgress,
    issueReason,
    time,
    handleModal,
    updateStage,
    setIssueReason,
    setTime,
}: IFormModalProps) => {
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [isTimeLogged, setIsTimeLogged] = useState<boolean>(false);
    const [message, setMessage] = useState<{ text: string, color: string } | null>(null);
    const apiUrl = useApiUrl();

    const handleButtonClick = (clickAction: string, nextStage = "initial") => {
        setSelectedAction(clickAction);
        updateStage(nextStage);
        if (nextStage === "process") {
            handleModal(false, { status: clickAction, id: item.id, prev_status: item.status });
        }
    }

    const onOkClick = async () => {
        if (stage === "report_issue" || (stage === "add_time" && isTimeLogged)) {
            if (stage === "add_time" && isTimeLogged) {
                try {
                    const response = await axiosInstance.post(`${apiUrl}/dashboard/developer/complete`, { minutes: time, item_id: item.id }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
    
                    if (response.status !== 200) {
                        console.error('Failed to log time');
                    }
                } catch (error) {
                    console.error('Error logging time', error);
                }
            }
    
            updateStage("process");
            handleModal(false, {
                status: selectedAction,
                id: item.id,
                prev_status: item.status,
                issue_reason: issueReason
            });
        } else {
            handleModal(true);
        }
    };    

    const onCancelClick = () => {
        handleModal(true);
    }

    var footer: JSX.Element[] = [];
    if (["initial", "report_issue", "add_time"].includes(stage)) {
        footer.push(<Button key="back" onClick={onCancelClick} style={{ borderRadius: '15px' }}>
            Cancel
        </Button>)
    }
    if (stage !== "process") {
        footer.push(<Button key="submit" type="primary"
            disabled={stage === "initial" || (stage === "add_time" && !isTimeLogged)}
            onClick={onOkClick} style={{ borderRadius: '15px' }}>
            Confirm
        </Button>)
    }

    const title: string = {
        "initial": "Perform action",
        "report_issue": "Report issue",
        "add_time": "Log Time",
        "process": "Processing...",
    }[stage] ?? "";

    return (
        <StyledModal
            open={open}
            onOk={onOkClick}
            onCancel={onCancelClick}
            title={title}
            centered
            footer={footer}
        >
            {stage === "initial" ? <InitialStage item={item} handleButtonClick={handleButtonClick} />
                : stage === "process" ?
                    <ProgressStage operationProgress={operationProgress} />
                    : stage === "add_time" ? <AddTime time={time} setTime={setTime} setIsTimeLogged={setIsTimeLogged} isTimeLogged={isTimeLogged} setMessage={setMessage} />
                        : <IssueStage issueReason={issueReason} setIssueReason={setIssueReason} />
            }
             {message && <div style={{ color: message.color, marginTop: '5px' }}>{message.text}</div>}
        </StyledModal>
    );
}