import React, { useEffect } from "react";
import { useList } from "@refinedev/core";
import { Col, Row, Typography } from "antd";

import { ToDoCard, ProgressGrid } from "dashboards/components/team-leader-dashboard";
import { SubjectCardData, ToDoData } from "interfaces";

import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const DashboardOverview: React.FC = () => {
    const { data, isLoading } = useList<SubjectCardData>({
        resource: "dashboard/team_leader",
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: "dashboard/team_leader/todo",
    });

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`Dashboard`);
    }, []);

    const cardsData = data?.data ?? [];
    const toDo = toDoData?.data as ToDoData | undefined;

    return <>
        <Row style={{ marginBottom: '24px' }} gutter={16}>
            <Col span={20}>
                <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Department Dashboard</Title>
            </Col>
            <Col span={4}>
                <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
            </Col>
        </Row>
        <Row>
            {!isLoading && <ProgressGrid cardsData={cardsData} />}
        </Row>
    </>
};