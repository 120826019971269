import React, { useEffect, useState } from 'react';
import { HttpError, useApiUrl, useOne } from "@refinedev/core";
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from "@refinedev/simple-rest";
import { Row, Col, Spin, message, Tabs, TabsProps } from 'antd';

import { sortQuestions } from 'utils/functions';

import { PdfViewer, QuestionGroup, UnusedPartsModal } from 'paper-splitter/components';
import { Pdf, PdfPage, Question, QuestionsData, SelectedPart } from 'interfaces';

import { useTitle } from 'contexts/TitleContext';
import { useQuestionsData } from 'contexts/QuestionsData';

import 'antd/dist/reset.css';


export const PaperSplitterView: React.FC = () => {
    const { paper } = useParams<{ paper: string }>();

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(paper ?? "Paper");
    }, []);

    const { questionsData, setQuestionsData } = useQuestionsData();

    const [questions, setQuestions] = useState<Question[]>([]);
    const [selectedParts, setSelectedParts] = useState<SelectedPart[]>([]);
    const [memoSelectedParts, setMemoSelectedParts] = useState<SelectedPart[]>([]);
    const [explanationSelectedParts, setExplanationSelectedParts] = useState<any>();
    const [selectionOpen, setSelectionOpen] = useState(false);
    const [percentComplete, setPercentComplete] = useState(0);

    const [currentPaperKey, setCurrentPaperKey] = useState<string>("1");
    const [customActiveTabKey, setCustomActiveTabKey] = useState<string>("1");

    const [editMode, setEditMode] = useState<boolean>(false);

    const [version, setVersion] = useState<number>(1);

    const apiUrl = useApiUrl();

    const navigate = useNavigate();

    const { data: pdf_data, isLoading } = useOne<Pdf, HttpError>({
        resource: "pdf",
        id: paper,
        meta: {
            version
        },
        errorNotification: (error: any) => {
            if (error.statusCode === 403) {
                return {
                    message: "Paper is closed",
                    description: "Error",
                    type: "error"
                }
            }
            else {
                return {
                    message: "An error occurred while fetching the paper",
                    description: "Error",
                    type: "error"
                }
            }

        }
    });

    const pdf = pdf_data?.data as unknown as Pdf;
    const pdf_pages = pdf?.pages ?? [];
    const memo_pdf_pages = pdf?.answer_pages ?? [];

    const syllabus_id = pdf?.syllabus_id ?? null;
    const memo_paper = paper?.replace("_qp_", "_ms_") ?? "";
    const pdf_id = pdf?.id;

    const paper_url = pdf?.url;
    const memo_paper_url = pdf?.answer_url;

    const [pages, setPages] = useState<PdfPage[]>([]);
    const [memo_pages, setMemoPages] = useState<PdfPage[]>([]);

    const [questionContentRefs, setQuestionContentRefs] = useState<Record<string, React.RefObject<HTMLDivElement>>>({});
    const [memoContentRefs, setMemoContentRefs] = useState<Record<string, React.RefObject<HTMLDivElement>>>({});

    const [unusedPartsShow, setUnusedPartsShow] = useState(false);
    const [unusedParts, setUnusedParts] = useState<SelectedPart[]>([]);

    useEffect(() => {
        if (!pdf) return;
        var questionData = false;
        const savedQuestionsData = localStorage.getItem('questionsData');
        if (!questionData && pdf.questions.length && paper) {
            const questionsSorted = sortQuestions(pdf.questions);
            setQuestions(questionsSorted);
            setQuestionsData({ paper, questions: questionsSorted });
        }
        else if (savedQuestionsData && paper) {
            const parsedQuestionsData = JSON.parse(savedQuestionsData);
            const paperQuestions = parsedQuestionsData[paper] as QuestionsData;
            if (parsedQuestionsData && paperQuestions) {
                questionData = true;
                setQuestionsData(paperQuestions);
                const questionsSorted = sortQuestions(pdf.questions);
                setQuestions(questionsSorted);
            }
        }
    }, [pdf]);

    useEffect(() => {
        if (pdf_pages.length) {
            setPages(pdf_pages);
        }

    }, [pdf_pages]);

    useEffect(() => {
        if (memo_pdf_pages.length) {
            setMemoPages(memo_pdf_pages);
        }
    }, [memo_pdf_pages]);

    useEffect(() => {
        if (pages || questions) {
            determinePercentComplete();
        }
    }, [pages, questions]);

    const determinePercentComplete = () => {
        var uniqueParts: SelectedPart[] = [];
        for (const question of questions) {
            for (const part of question.parts) {
                if (!uniqueParts.find((p) => p.part.id === part.part.id)) {
                    uniqueParts.push(part);
                }
            }
        }
        var total_parts = 0;
        for (const page of pages) {
            total_parts += page.parts.length;
        }
        const percent = Math.round((uniqueParts.length / total_parts) * 100);
        setPercentComplete(percent);
    }

    const handleUpdateQuestions = (newQuestions: Question[]) => {
        setQuestions(newQuestions);
        const updatedQuestionsData = {
            paper: questionsData ? questionsData.paper : paper,
            questions: newQuestions
        };
        setQuestionsData(updatedQuestionsData);
        const currentData = localStorage.getItem('questionsData');
        if (currentData) {
            const parsedData = JSON.parse(currentData);
            parsedData[updatedQuestionsData.paper] = updatedQuestionsData;
            localStorage.setItem('questionsData', JSON.stringify(parsedData));
        }
        else {
            var newData: any = {};
            newData[updatedQuestionsData.paper] = updatedQuestionsData;
            localStorage.setItem('questionsData', JSON.stringify(newData));
        }
    }

    const handleUpdateSelectedParts = (parts: SelectedPart[]) => {
        if (!selectionOpen) setSelectionOpen(true);
        else if (parts.length === 0) setSelectionOpen(false);

        setSelectedParts(parts);

        if (parts.length > 0 && parts[0] && currentPaperKey === "1") {
            scrollToPart(parts[0].part.id, "q");
        }

    };

    const handleUpdateMemoSelectedParts = (parts: SelectedPart[]) => {
        if (!selectionOpen) setSelectionOpen(true);
        else if (parts.length === 0) setSelectionOpen(false);

        setMemoSelectedParts(parts);

        if (parts.length > 0 && currentPaperKey === "2" && parts[0] !== undefined) {
            scrollToPart(parts[0].part.id, "m");
        }
    };

    const handleUpdateExplanationSelectedParts = (parts: SelectedPart[]) => {
        if (!selectionOpen) setSelectionOpen(true);
        else if (parts.length === 0) setSelectionOpen(false);

        setExplanationSelectedParts(parts);
    };

    const scrollToPart = (partId: number, paperKey: string) => {
        const element = document.getElementById(`${paperKey}_${partId}`);
        if (element) {
            const yOffset = -50;
            const yPosition = element.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: yPosition, behavior: 'smooth' });
        }
    }

    const handleUpdatePaperKey = (key: string) => {
        if (key === "1" || key === "2") {
            setCustomActiveTabKey(key);
        }
        setCurrentPaperKey(key);
        setTimeout(() => {
            if (selectedParts.length > 0 && key === "1") {
                scrollToPart(selectedParts[0].part.id, "q");
            }
            else if (memoSelectedParts.length > 0 && key === "2") {
                scrollToPart(memoSelectedParts[0].part.id, "m");
            }
        }, 100);
    }

    const updateMemoPages = (newPages: PdfPage[]) => {
        setMemoPages(newPages);
    };

    const handleInsertQuestion = async (question: Question) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/question/add`, question, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });
            if (response.status === 200) {
                return response.data?.data?.id as number;
            }
        } catch (error) {
            message.error('An error occurred while adding the question');
        }
        return question.id;
    }

    const handleUpdateQuestion = async (question: Question) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/question/${question.id}/update`, question, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });
        } catch (error) {
            message.error('An error occurred while updating the question');
        }
    }

    const handleDeleteQuestion = async (questionId: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/question/${questionId}/delete`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });
        } catch (error) {
            message.error('An error occurred while deleting the question');
        }
    }

    const handleRemoveExplanation = async (questionId: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/question/${questionId}/remove-explanation`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });
        } catch (error) {
            message.error('An error occurred while deleting the question');
        }
    }

    const handleFindUnusedParts = () => {
        setEditMode(false);
        var unusedParts: SelectedPart[] = [];
        for (const page of pages) {
            for (const part of page.parts) {
                if (!questions.find((q) => q.parts.find((p) => p.part.id === part.id))) {
                    unusedParts.push({ page: page.page, part });
                }
            }
        }
        unusedParts.sort((a, b) => {
            if (a.page < b.page) return -1;
            if (a.page > b.page) return 1;
            return a.part.name < b.part.name ? -1 : 1;
        });
        setUnusedParts(unusedParts);
        setUnusedPartsShow(true);
    }

    const handleFinish = async () => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/pdf/${paper}/split`, questions, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });
            navigate(`/papers`);
        } catch (error) {
            message.error('An error occurred while submitting the questions');
        }
    }

    const handlePartFlash = (partId: number, paperKey: string) => {
        const part = document.getElementById(`${paperKey}_${partId}`);
        if (part) {
            const originalClassName = part.className;
            const originalTransition = part.style.transition;

            part.style.transition = "background-color 0.7s, opacity 0.7s";
            part.className = originalClassName + " flash";

            setTimeout(() => {
                part.className = originalClassName;
                setTimeout(() => {
                    part.style.transition = originalTransition;
                }, 1000);
            }, 1500);
        }
    }

    const closeAndScroll = (partId: number, paperKey: string) => {
        setUnusedPartsShow(false);
        var timeout = 0;
        const pKey = paperKey === "q" ? "1" : "2"
        if (currentPaperKey !== pKey) {
            setCurrentPaperKey(pKey);
            timeout = 300;
        }
        setTimeout(() => {
            scrollToPart(partId, paperKey);
            setTimeout(() => {
                handlePartFlash(partId, paperKey);
            }, 300);
        }, timeout);

    }

    const tabItems: TabsProps['items'] = [
        {
            key: "1",
            label: "Question Paper",
            children: <Spin spinning={isLoading}>
                <PdfViewer
                    url={paper_url}
                    paper={paper as string}
                    pages={pages}
                    pdf_id={pdf_id}
                    syllabus_id={syllabus_id}
                    selectedParts={selectedParts}
                    editMode={editMode}
                    contentRefs={questionContentRefs}
                    paper_type="qp"
                    setSelectedParts={handleUpdateSelectedParts}
                    setVersion={setVersion}
                    setPages={setPages}
                />
            </Spin>
        },
        {
            key: "2",
            label: "Marking Scheme",
            children: <Spin spinning={isLoading}>
                <PdfViewer
                    url={memo_paper_url}
                    paper={memo_paper as string}
                    pages={memo_pages}
                    pdf_id={pdf_id}
                    syllabus_id={syllabus_id}
                    selectedParts={memoSelectedParts}
                    editMode={editMode}
                    contentRefs={memoContentRefs}
                    paper_type="ms"
                    setSelectedParts={handleUpdateMemoSelectedParts}
                    setVersion={setVersion}
                    setPages={setMemoPages}
                />
            </Spin>
        }

    ]

    return (
        <Row>
            <Col span={12}>
                <Tabs
                    defaultActiveKey='1'
                    activeKey={customActiveTabKey}
                    items={tabItems}
                    onChange={(key) => {
                        setCurrentPaperKey(key);
                        setCustomActiveTabKey(key);
                    }}

                />
            </Col>
            <Col span={12}>
                <QuestionGroup
                    syllabus_id={syllabus_id}
                    questions={questions}
                    selectionOpen={selectionOpen}
                    selectedParts={selectedParts}
                    memoSelectedParts={memoSelectedParts}
                    explanationSelectedParts={explanationSelectedParts}
                    percentComplete={percentComplete}
                    paper={paper ?? ""}
                    editMode={editMode}
                    currentPaperKey={currentPaperKey}
                    handleInsertQuestion={handleInsertQuestion}
                    handleUpdateQuestion={handleUpdateQuestion}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleRemoveExplanation={handleRemoveExplanation}
                    handleFindUnusedParts={handleFindUnusedParts}
                    handleFinish={handleFinish}
                    setSelectionOpen={setSelectionOpen}
                    setSelectedParts={handleUpdateSelectedParts}
                    setMemoSelectedParts={handleUpdateMemoSelectedParts}
                    setExplanationSelectedParts={handleUpdateExplanationSelectedParts}
                    updateQuestions={handleUpdateQuestions}
                    setEditMode={setEditMode}
                    setCurrentPaperKey={handleUpdatePaperKey}
                />
            </Col>
            <UnusedPartsModal
                unusedParts={unusedParts}
                unusedPartsShow={unusedPartsShow}
                setUnusedPartsShow={setUnusedPartsShow}
                closeAndScroll={closeAndScroll}
            />
        </Row>
    )
}