import React, { useEffect, useState } from 'react';
import { Tabs, Modal, Switch, TabsProps } from 'antd';
import { CheckOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { HttpError, useList } from '@refinedev/core';

import { QuestionsContent } from './tabs/questions';
import { blankQuestion, default_section, default_topic, questionTabName, selectionTabName } from './tabs/constants';
import { SelectionContent } from './tabs/selection';
import {
    Question,
    ISection as Section,
    SectionOption,
    SelectedPart,
    ITopic as Topic,
    TopicOption,
    InputStatus
} from 'interfaces';

import './group.css';

const { confirm } = Modal;

type QuestionGroupProps = {
    syllabus_id: string | null;
    questions: Question[];
    selectedParts: SelectedPart[];
    memoSelectedParts: SelectedPart[];
    explanationSelectedParts: any;
    selectionOpen: boolean;
    percentComplete: number;
    paper: string;
    editMode: boolean;
    currentPaperKey: string;
    updateQuestions: (questions: Question[]) => void;
    setSelectionOpen: (open: boolean) => void;
    setSelectedParts: (parts: SelectedPart[]) => void;
    setMemoSelectedParts: (parts: SelectedPart[]) => void;
    setExplanationSelectedParts: (parts: any) => void;
    handleInsertQuestion: (question: Question) => Promise<number>;
    handleUpdateQuestion: (question: Question) => void;
    handleDeleteQuestion: (questionId: number) => void;
    handleRemoveExplanation : (questionId: number) => void;
    handleFindUnusedParts: () => void;
    handleFinish: () => void;
    setEditMode: (checked: boolean) => void;
    setCurrentPaperKey: (key: string) => void;
};

type QuestionDataProps = {
    newQuestionId?: number;
    newSelectedParts?: SelectedPart[];
    newSelectedMemoParts?: SelectedPart[];
    newSelectedExplanationParts?: any;
    newSection?: Section;
    newTopic?: Topic;
    newQuestionName?: string;
    newMCAnswer?: string;
    newState?: string;
    newType?: string;
}

export const QuestionGroup: React.FC<QuestionGroupProps> = ({
    syllabus_id,
    questions,
    selectedParts,
    memoSelectedParts,
    explanationSelectedParts,
    selectionOpen,
    percentComplete,
    paper,
    editMode,
    currentPaperKey,
    handleInsertQuestion,
    handleUpdateQuestion,
    handleDeleteQuestion,
    handleRemoveExplanation,
    updateQuestions,
    setSelectionOpen,
    setSelectedParts,
    setMemoSelectedParts,
    setExplanationSelectedParts,
    handleFindUnusedParts,
    handleFinish,
    setEditMode,
    setCurrentPaperKey
}) => {
    const [activeTab, setActiveTab] = useState(questionTabName);
    const [currentQuestionId, setCurrentQuestionId] = useState<number>(-1);
    const [questionName, setQuestionName] = useState('');
    const [mcAnswer, setMCAnswer] = useState('');
    const [section, setSection] = useState<Section>(default_section);
    const [topic, setTopic] = useState<Topic>(default_topic);

    const [questionNameStatus, setQuestionNameStatus] = useState<InputStatus>('');
    const [mcAnswerStatus, setMCAnswerStatus] = useState<InputStatus>('');
    const [sectionStatus, setSectionStatus] = useState<InputStatus>('');
    const [topicStatus, setTopicStatus] = useState<InputStatus>('');
    const [filteredTopics, setFilteredTopics] = useState<Topic[]>([]);

    const [sectionInputValue, setSectionInputValue] = useState<string>('');
    const [topicInputValue, setTopicInputValue] = useState<string>('');

    const [questionState, setQuestionState] = useState<string>('Usable');
    const [questionType, setQuestionType] = useState<string>('SQ');

    const { data: section_data } = useList<Section, HttpError>({
        resource: `pdf/${paper}/sections?syllabus_id=${syllabus_id}`
    });

    const { data: topic_data } = useList<Topic, HttpError>({
        resource: `pdf/${paper}/topics?syllabus_id=${syllabus_id}`
    });

    const sections = section_data?.data ?? [];
    const topics = topic_data?.data ?? [];

    useEffect(() => {
        setFilteredTopics(topics);
    }, [topics]);

    useEffect(() => {
        if (selectionOpen) {
            setActiveTab(selectionTabName);
        }
    }, [selectionOpen]);

    const newQuestion = () => {
        setActiveTab(selectionTabName);
    };

    const editQuestion = (question: Question) => {
        const newQuestionId = question.id;
        const newSelectedParts = question.parts;
        const newSelectedMemoParts = question.memo_parts;
        const newSelectedExplanationParts = question.explanation;
        const newSection = question.section;
        const newTopic = question.topic;
        const newQuestionName = question.title;
        const newState = question.status;
        const newType = question.type;
        const newMCAnswer = question.mc_answer;
        setCurrentQuestionData({ newQuestionId, newSelectedParts, newSelectedMemoParts,newSelectedExplanationParts, newSection, newTopic, newQuestionName, newState, newType, newMCAnswer });
        setActiveTab(selectionTabName);
    }

    const selectQuestion = (question: Question) => {
        editQuestion(question);
    }

    const setCurrentQuestionData = (props: QuestionDataProps | null = null) => {
        const {
            newQuestionId = -1,
            newSelectedParts = [],
            newSelectedMemoParts = [],
            newSelectedExplanationParts,
            newSection = default_section,
            newTopic = default_topic,
            newQuestionName = '',
            newMCAnswer = '',
            newState = 'Usable',
            newType = 'SQ'
        } = (props ?? {}) as QuestionDataProps;

        setCurrentQuestionId(newQuestionId);
        setSelectedParts(newSelectedParts);
        setMemoSelectedParts(newSelectedMemoParts);
        setExplanationSelectedParts(newSelectedExplanationParts);
        setQuestionName(newQuestionName);
        setMCAnswer(newMCAnswer);
        setSection(newSection);
        setSectionInputValue(newSection.name);
        setTopic(newTopic);
        setTopicInputValue(newTopic.name);
        setFilteredTopics(newSection.id !== -1 ? topics.filter((topic) => topic.section_id === newSection.id) : topics)
        setQuestionState(newState);
        setQuestionType(newType);
    };

    const onTabChange = (key: string) => {
        setActiveTab(key);
        setSelectionOpen(key === selectionTabName);
        if (key === questionTabName) {
            setCurrentQuestionData(null)
        }
    };

    const showConfirm = () => {
        const isComplete = percentComplete >= 100;
        if (isComplete) {
            confirm({
                title: 'Confirm finish',
                icon: <ExclamationCircleOutlined />,
                content: 'Are you sure you want to finish?',
                onOk: () => handleFinish(),
                onCancel: () => { },
            });
        }
        else {
            confirm({
                title: `Current progress: ${percentComplete}%`,
                icon: <ExclamationCircleOutlined />,
                content: 'You must complete all questions before finishing!',
                onOk: () => { },
                onCancel: () => { },
            });
        }
    };

    const handleQuestionNameChange = (value: string) => {
        setQuestionNameStatus('')
        setQuestionName(value)
    }

    const handleMCAnswerChange = (value: string) => {
        setMCAnswerStatus('')
        setMCAnswer(value)
    }

    const handleSelectSection = (option: SectionOption) => {
        setFilteredTopics(topics.filter((topic) => topic.section_id === option.value));
        setSectionStatus('');
        setSection(sections.find((section) => section.id === option.value) ?? default_section);
        setSectionInputValue(option.label);
    }

    const handleOnSectionChange = (value: string) => {
        if (value === '') {
            setFilteredTopics(topics);
        }
        setTopicInputValue('');
        setTopic(default_topic);
    }

    const handleOnTopicSelect = (option: TopicOption) => {
        if (section.id !== option.section_id) {
            const sectionFromTopic = sections.find((section) => section.id === option.section_id) ?? default_section;
            setSection(sectionFromTopic);
            setSectionInputValue(sectionFromTopic.name);
            setSectionStatus('');
            setFilteredTopics(topics.filter((topic) => topic.section_id === sectionFromTopic.id));
        }
        setTopicStatus('');
        setTopic(topics.find((topic) => topic.id === option.value) ?? default_topic);
        setTopicInputValue(option.label);
    }

    const updateStatus = (newQuestionNameStatus: InputStatus, newSectionStatus: InputStatus, newTopicStatus: InputStatus, newMCAnswerStatus: InputStatus) => {
        setQuestionNameStatus(newQuestionNameStatus);
        setSectionStatus(newSectionStatus);
        setTopicStatus(newTopicStatus);
        setMCAnswerStatus(newMCAnswerStatus);
    }

    const modifyQuestion = (newQuestions: Question[], tabName: string) => {
        updateQuestions(newQuestions);
        setCurrentQuestionData(null);
        setActiveTab(tabName);
    }

    const handleEditModeChange = (checked: boolean) => {
        setEditMode(checked);
        if (checked && activeTab === selectionTabName) {
            setActiveTab(questionTabName);
            setSelectionOpen(false);
            setSelectedParts([]);
            setMemoSelectedParts([]);
        }
    }

    const tabItems: TabsProps['items'] = [
        {
            key: "questions",
            label: "Questions",
            children: <QuestionsContent
                questions={questions}
                blankQuestion={blankQuestion}
                percentComplete={percentComplete}
                editMode={editMode}
                newQuestion={newQuestion}
                selectQuestion={selectQuestion}
                editQuestion={editQuestion}
                showConfirm={showConfirm}
                showUnusedParts={handleFindUnusedParts}
            />
        },
        {
            key: "selection",
            label: "Selection",
            children: <SelectionContent
                questions={questions}
                currentQuestionId={currentQuestionId}
                section={section}
                topic={topic}
                questionNameStatus={questionNameStatus}
                questionName={questionName}
                mcAnswerStatus={mcAnswerStatus}
                mcAnswer={mcAnswer}
                sectionStatus={sectionStatus}
                topicStatus={topicStatus}
                sections={sections}
                filteredTopics={filteredTopics}
                sectionInputValue={sectionInputValue}
                topicInputValue={topicInputValue}
                selectedParts={selectedParts}
                memoSelectedParts={memoSelectedParts}
                explanationSelectedParts= {explanationSelectedParts}
                questionState={questionState}
                questionType={questionType}
                currentPaperKey={currentPaperKey}
                handleQuestionNameChange={handleQuestionNameChange}
                handleMCAnswerChange={handleMCAnswerChange}
                handleSelectSection={handleSelectSection}
                handleOnSectionChange={handleOnSectionChange}
                handleOnTopicSelect={handleOnTopicSelect}
                setSectionInputValue={setSectionInputValue}
                setTopicInputValue={setTopicInputValue}
                setQuestionState={setQuestionState}
                setQuestionType={setQuestionType}
                updateStatus={updateStatus}
                insertQuestionApi={handleInsertQuestion}
                updateQuestionApi={handleUpdateQuestion}
                deleteQuestionApi={handleDeleteQuestion}
                removeExplanationApi={handleRemoveExplanation}
                modifyQuestion={modifyQuestion}
                setCurrentPaperKey={setCurrentPaperKey}
            />
        }
    ]

    return (
        <div className="container">
            <div className="switch-container">
                <Switch
                    className="switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<EditOutlined />}
                    checked={editMode}
                    onChange={handleEditModeChange}
                />
            </div>
            <Tabs activeKey={activeTab} items={tabItems} onChange={onTabChange} />
        </div>
    );
};