
import { Button, Col, ConfigProvider, DatePicker, Form, Input, Progress, ProgressProps, Row, Select, Space } from "antd";
import { SolutionOutlined, CheckOutlined, IdcardOutlined, CloseOutlined, UserAddOutlined, LinkOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { buildSheetUrl, getStatusClass } from 'utils/functions';
import { ContentItemDetail, IActionObject, OperationProgress, ReviewContentItemDetail, User } from "interfaces";
import dayjs from "dayjs";
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FormButton, ProgressContains, ProgressMessage, StyledModal } from "../../actionmodal.styled";

dayjs.extend(customParseFormat);

interface IFormModalProps {
    stage: string;
    item: ContentItemDetail | ReviewContentItemDetail;
    users: User[];
    subsheetId: string;
    open: boolean;
    operationProgress: OperationProgress;
    updateStage: React.Dispatch<React.SetStateAction<string>>;
    handleModal: (isCancel: boolean, action?: IActionObject) => void;
}

interface IInitialStageProps {
    item: ContentItemDetail | ReviewContentItemDetail;
    subsheetId: string;
    handleButtonClick: (clickAction: string, nextStage?: string) => void;
}

interface AddUserStageProps {
    handleButtonClick: (clickAction: string, nextStage?: string) => void;
}

interface IAssignStageProps {
    users: User[];
    setDueDate?: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<number>>;
}

interface UserInfoProps {
    setName: React.Dispatch<React.SetStateAction<string>>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
}


interface IProgressProps {
    operationProgress: OperationProgress;
}

interface IColorButtonProps {
    btnColor: string;
    btnNode: React.ReactNode;
}

const issueReasonAbbreviations: { [key: string]: string } = {
    "Broken sheet - value errors etc.": "Subsection",
    "Cannot access sheet": "No Access",
    "Categories are wrong": "Categories",
    "Expeditions are wrong": "Expedition",
    "Levels are wrong": "Level",
    "Textbooks are wrong": "Textbooks",
    "Wrong sheet assigned": "Sheet"
  };

interface CustomLinkButtonProps {
    url: string;
    color: string;
    text: string;
}

const CustomLinkButton: React.FC<CustomLinkButtonProps> = ({ url, color, text }) => (
    <Link to={url} target='_blank' rel='noopener noreferrer' style={{ width: '100%' }}>
        <Button
            type="primary"
            style={{ width: '100%', backgroundColor: color, borderColor: color, borderRadius: '15px' }}
            icon={<LinkOutlined />}
        >
            {text}
        </Button>
    </Link>
);

const ColorButton = ({ btnColor, btnNode }: IColorButtonProps) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: btnColor,
                },
            }}
        >
            {btnNode}
        </ConfigProvider>
    )

}

const InitialStage = ({ item, subsheetId, handleButtonClick }: IInitialStageProps) => {
    const disabledRules = {
        "Assign": false,
        "Update": item.status !== 'Complete',
    }
    const abbreviation = issueReasonAbbreviations[item.issue_reason] || "Resolve";
    const showResolveButton = abbreviation !== "Resolve";
    
    return (
        <Row gutter={[8, 16]} justify="center">
            {showResolveButton && (
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <ColorButton
                        btnColor="#f5222d"
                        btnNode={
                            <FormButton
                                icon={<CloseOutlined />}
                                onClick={() => handleButtonClick('Resolve', 'process')}
                                type="primary"
                                style={{ width: '100%' }}
                            >
                                {`Reset ${abbreviation}`}
                            </FormButton>
                        }
                    />
                </Col>
            )}
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <CustomLinkButton
                    url={buildSheetUrl(subsheetId)}
                    color="#3b5998"
                    text="Link To Syllabus"
                />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <ColorButton
                    btnColor={'#f57c00'}
                    btnNode={<FormButton
                        icon={<IdcardOutlined />}
                        onClick={() => handleButtonClick('Update', 'assign')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Re-Assign
                    </FormButton>}
                />
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <ColorButton
                    btnColor={'#00b96b'}
                    btnNode={<FormButton
                        icon={<CheckOutlined />}
                        onClick={() => handleButtonClick('Prepped', 'process')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Mark Prepped
                    </FormButton>}
                />
            </Col>
            {abbreviation === "No Access" && (
            <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <ColorButton
                    btnColor={'#008CBA'}
                    btnNode={<FormButton
                        icon={<PlusOutlined />}
                        onClick={() => handleButtonClick('AddUser', 'add_user')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Add user
                    </FormButton>}
                />
            </Col>
            )}
        </Row>
    )
}

const AddUserStage = ({handleButtonClick }: AddUserStageProps) => {
    return (
        <Row gutter={[8, 16]} justify="center">

            <Col span={8}>
                <ColorButton
                    btnColor={'#1890ff'}
                    btnNode={<FormButton
                        icon={<UserAddOutlined />}
                        onClick={() => handleButtonClick('AddUser', 'add_drive_user')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Drive user
                    </FormButton>}
                />
            </Col>
            <Col span={8}>
                <ColorButton
                    btnColor={'#f57c00'}
                    btnNode={<FormButton
                        icon={<IdcardOutlined />}
                        onClick={() => handleButtonClick('AddDashUser', 'add_dashboard_user')}
                        type="primary"
                        style={{ width: '100%' }}
                    >
                        Dashboard user
                    </FormButton>}
                />
            </Col>
        </Row>
    )
}

const UserInfo = ({ setName, setEmail, setIsFormValid }: UserInfoProps) => {
    const [localName, setLocalName] = useState<string>('');
    const [localEmail, setLocalEmail] = useState<string>('');

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLocalName(value);
        setName(value);
        validateForm(value, localEmail);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLocalEmail(value);
        setEmail(value);
        validateForm(localName, value);
    };

    const validateForm = (name: string, email: string) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isEmailValid = emailPattern.test(email);
        const isNameValid = name.trim() !== '';
        setIsFormValid(isNameValid && isEmailValid);
    };


    return (
        <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name' }]}
            >
                <Input placeholder="Your name" onChange={handleNameChange} />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please enter your email' }]}
            >
                <Input placeholder="Your email" onChange={handleEmailChange} />
            </Form.Item>
        </Form>
    );
};

const DashboardUser = ({ users, setUser }: IAssignStageProps) => {
    return (
        <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
    >
        <Form.Item label="Assign to" rules={[{ required: true, message: "Please select a user" }]}>
            <Select onChange={(e) => setUser(e)}>
                {users?.map((ele) => {
                    return <Select.Option value={ele.id} key={`${ele.name}-${ele.id}`}>{ele.name}</Select.Option>
                })}
            </Select>
        </Form.Item>
    </Form>
    )
}

const AssignStage = ({ users, setDueDate, setUser }: IAssignStageProps) => {
    const dateFormat = 'DD-MM-YYYY';
    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Assign to" rules={[{ required: true, message: "Please select a user" }]}>
                <Select onChange={(e) => setUser(e)}>
                    {users?.map((ele) => {
                        return <Select.Option value={ele.id} key={`${ele.name}-${ele.id}`}>{ele.name}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            {setDueDate && (
                <Form.Item
                    label="Due date"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Please select a due date')),
                        },
                    ]}
                >
                    <DatePicker
                        placeholder="Select due date"
                        onChange={(date: any) => setDueDate(date.format("DD/M/YYYY"))}
                        format={dateFormat}
                        disabledDate={(current) => current && current < dayjs().startOf('day')}
                    />
                </Form.Item>
            )}
        </Form>
    )
}

const ProgressStage = ({ operationProgress }: IProgressProps) => {
    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    return (
        <ProgressContains>
            <Progress
                percent={operationProgress.percent ?? 0}
                status="active"
                showInfo={true}
                strokeColor={twoColors}
            />
            <CSSTransition
                in={!!operationProgress.message}
                timeout={300}
                classNames="message"
                unmountOnExit
            >
                {state => (
                    <ProgressMessage $in={state === 'entered'}>
                        {operationProgress.message ?? ""}
                    </ProgressMessage>
                )}
            </CSSTransition>
        </ProgressContains>
    )
}

export const IssueFormModal = ({
    stage,
    item,
    users,
    subsheetId,
    open,
    operationProgress,
    handleModal,
    updateStage,
}: IFormModalProps) => {
    
    const [user, setUser] = useState<number>(0);
    const [dueDate, setDueDate] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [history, setHistory] = useState<string[]>([]);

    useEffect(() => {
        setHistory(prevHistory => [...prevHistory, stage]);
    }, [stage]);
    
    const handleButtonClick = (clickAction: string, nextStage = "initial") => {
        setSelectedAction(clickAction);
        updateStage(nextStage);
        if (nextStage === "process") {
            handleModal(false, { status: clickAction, id: item.id, prev_status: item.status });
        }
    }

    const onOkClick = () => {
        if (stage === "assign") {
            updateStage("process");
            const selectedUser = users?.filter(ele => ele.id === user)[0];
            if (!selectedUser || !selectedUser?.id || !dueDate) {
                handleModal(true);
            }
            else {
                handleModal(false, {
                    status: selectedAction,
                    id: item.id,
                    prev_status: item.status,
                    ...((selectedUser?.id || dueDate) && {
                        assigned_to_1: selectedUser?.id,
                        assigned_due: dueDate,
                    })
                })
            }
        }

        else if (stage === "add_drive_user") {
            updateStage("process");
            handleModal(false, {
                status: selectedAction,
                id: item.id,
                prev_status: item.status,
                name: name,
                email: email,
            })
        }
        else if (stage === "add_dashboard_user") {
            updateStage("process");
            const selectedUser = users?.filter(ele => ele.id === user)[0];
            handleModal(false, {
                status: selectedAction,
                id: item.id,
                prev_status: item.status,
                ...((selectedUser?.id || dueDate) && {
                    user_id: selectedUser?.id,
                    name: selectedUser.name,
                })
            })
        }
        else {
            handleModal(true);
        }
        setName('');
        setEmail('');
        setIsFormValid(false);
    }

    const onCancelClick = () => {
        handleModal(true);
        setName('');
        setEmail('');
        setIsFormValid(false);
    }

    const onBackClick = () => {
        setHistory(prevHistory => {
            const newHistory = [...prevHistory];
            newHistory.pop();
            updateStage(newHistory[newHistory.length - 1]);
            return newHistory;
        });
    };

    var footer: JSX.Element[] = [];

    if (["assign", "add_user", "add_drive_user", "add_dashboard_user"].includes(stage)) {
        footer.push(<Button key="back" onClick={onBackClick} style={{ borderRadius: '15px' }}>
            Back
        </Button>);
    }
    if (["initial", "assign", "add_user"].includes(stage)) {
        footer.push(<Button key="back" onClick={onCancelClick} style={{ borderRadius: '15px' }}>
            Cancel
        </Button>)
    }
    if (stage !== "process") {
        footer.push(<Button key="submit" type="primary" disabled={stage == "initial" || (stage === "add_drive_user" && !isFormValid)} onClick={onOkClick} style={{ borderRadius: '15px' }}>
            Confirm
        </Button>)
    }

    const title: string = {
        "initial": "Perform action",
        "assign": "Perform action",
        "add_drive_user": "Add google drive user",
        "add_dashboard_user": "Add dashboard user",
        "add_user": "Add User",
        "process": "Processing...",
    }[stage] ?? "";
    
    return (
        <StyledModal
            open={open}
            onOk={onOkClick}
            onCancel={onCancelClick}
            title={title}
            centered
            footer={footer}
        >
            {stage === "initial" ? <InitialStage item={item} subsheetId={subsheetId} handleButtonClick={handleButtonClick} />
                : stage === "assign" ?
                    <AssignStage
                        users={users}
                        setDueDate={setDueDate}
                        setUser={setUser}
                    />
                    : stage === "add_drive_user" ? <UserInfo setName={setName} setEmail={setEmail} setIsFormValid={setIsFormValid} />
                    : stage ==="add_dashboard_user" ? <DashboardUser users={users} setUser={setUser}/>
                    : stage ==="add_user" ? <AddUserStage handleButtonClick={handleButtonClick}/>
                    : <ProgressStage operationProgress={operationProgress} />
    
            }
        </StyledModal>
    );
}
