import React, { useState } from 'react';
import { DeveloperContentItemDetail } from 'interfaces';

import { Col } from 'antd';

import "antd/dist/reset.css";
import { Link } from 'react-router-dom';
import { buildSheetUrl, getStatusClass } from 'utils/functions';
import moment from 'moment';
import { SortOrder } from 'antd/es/table/interface';
import { IssueModal } from '../IssueModal';
import { StyledButton, StyledTable } from '../../dashboards.styled';

interface DeveloperTableProps {
  tableData: DeveloperContentItemDetail[];
  handleActionButtonClick: (subject_name: string, item: DeveloperContentItemDetail) => void;
}

export const DeveloperTable: React.FC<DeveloperTableProps> = ({
  tableData,
  handleActionButtonClick,
}) => {

  const columns = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a: any, b: any) => {
        const getNumericLevel = (level: string) => parseInt(level.replace(/\D/g, ''), 10);
        return getNumericLevel(a.level) - getNumericLevel(b.level);
      },
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => {
        const contentText = `Past Paper: ${record.level}`;
        if (record.content && record.content === "Past Papers") {
          return record.status !== "Not Started" ? (
            <Link
              to={`/papers/${record.level}`}
              style={{ color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={"section-cell connected"}>
                <span style={{ paddingLeft: "1rem" }}>{contentText}</span>
              </div>
            </Link>
          ):(
            <div className={"section-cell"}>
              <span style={{ paddingLeft: "1rem" }}>{contentText}</span>
            </div>
          );
        }
        return record.capture_id && record.status !== "Complete" ? (
          <Link
            to={buildSheetUrl(record.capture_id)}
            style={{ color: '#000' }}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
        ):(
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      sorter: (a: any, b: any) => (a.content || a.content_title).localeCompare(b.content || b.content_title),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => {
        const displayText = record.content_title || text;
        if (record.content && record.content === "Past Papers") {
          return record.status !== "Not Started" ? (
            <Link
              to={`/papers/${record.level}`}
              style={{ color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={"section-cell middle-cell connected"}>
                <span style={{ paddingLeft: "1rem" }}>{displayText}</span>
              </div>
            </Link>
          ):(
            <div className={"section-cell middle-cell connected"}>
              <span style={{ paddingLeft: "1rem" }}>{displayText}</span>
            </div>
          );
        }
        return record.capture_id && record.status !== "Complete" ? (
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell middle-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{displayText}</span>
            </div>
          </Link>
        ) : (
          <div className={"section-cell middle-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{displayText}</span>
          </div>
        );
      },
    },

    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a: any, b: any) => a.subject.localeCompare(b.subject),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => {
        if (record.content && record.content === "Past Papers") {
          return record.status !== "Not Started" ? (
            <Link
              to={`/papers/${record.level}`}
              style={{ color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={"section-cell connected"}>
                <span style={{ paddingLeft: "1rem" }}>{text}</span>
              </div>
            </Link>
          ):(
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: "1rem" }}>{text}</span>
            </div>
          );
        }
        return record.capture_id && record.status !== "Complete" ? (
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
        ):(
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
        );
      },
    },

    {
      title: 'Due Date',
      dataIndex: 'assigned_due',
      key: 'assigned_due',
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: any, b: any) => {
        if (!(moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "Reassigned", "In Progress"].includes(a.status))) {
          return 1;
        }
        if (!(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "Reassigned", "In Progress"].includes(b.status))) {
          return -1;
        }
        return moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isBefore(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true)) ? -1 : 1;
      },
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => (
        text && record.status !== "Complete" ? moment(text, "DD/MM/YYYY").format("D MMM YYYY") : ""
      )
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (_: string, record: any) => {
        const isPastPapers = record.content_title === "Past Papers";
        return (
          <div style={{ position: 'relative'}}>
            <StyledButton
              onClick={() => handleActionButtonClick(record.subject, record)}
              type="primary"
              className={isPastPapers ? "disabled" : ""}
              disabled={isPastPapers}
            >
              Action
            </StyledButton>
          </div>
        );
      },
    }    
  ];

  const [open, setOpen] = useState(false);
  const [issueText, setIssueText] = useState(<></>);

  const onOkClick = () => {
    setOpen(false);
    setIssueText(<></>);
  }

  const handleMouseEnterConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.add('hover-effect');
    });
  };

  const handleMouseLeaveConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.remove('hover-effect');
    });
  };

  return (
    <Col span={20}>
      <StyledTable
        borderBottomRightRadius
        borderTopRightRadius
        borderBottomLeftRadius
        borderTopLeftRadius
        $padding
        borderRadius
        paddingLeft
        columns={columns}
        dataSource={tableData}
      />
      {open && <IssueModal
        open={open}
        onOkClick={onOkClick}
        issueText={issueText}
      />}
    </Col>
  )
}
