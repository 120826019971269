import { Button, Form, FormInstance, Input, Modal, Spin, Tabs, TabsProps, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { axiosInstance } from '@refinedev/simple-rest';
import { useApiUrl } from "@refinedev/core";
import { IImage, ImageUrl } from "interfaces";

const { Text } = Typography;

type MainModalProps = {
    isModalOpen: boolean;
    generatedPDF: any;
    isGeneratingPDF: boolean;
    previewImage: any;
    selectedImages: any[];
    isFlagFormOpen: boolean;
    flagReason: string;
    setIsModalOpen: (value: boolean) => void;
    handleAddOrRemove: () => void;
    handleFlag: (image: any, action: string) => void;
    setIsFlagFormOpen: (value: boolean) => void;
    setFlagReason: (value: string) => void;
}

type FinishModalProps = {
    isFinishModalOpen: boolean;
    isFinalizing: boolean;
    form: FormInstance;
    setIsFinishModalOpen: (value: boolean) => void;
    handleFinishSubmit: () => void;
}

export const MainModal: React.FC<MainModalProps> = ({
    isModalOpen,
    generatedPDF,
    isGeneratingPDF,
    previewImage,
    selectedImages,
    isFlagFormOpen,
    flagReason,
    setIsModalOpen,
    handleAddOrRemove,
    handleFlag,
    setIsFlagFormOpen,
    setFlagReason
}) => {
    const apiUrl = useApiUrl();

    const [maxWidth, setMaxWidth] = useState<number>(0);
    const [maxMemoWidth, setMaxMemoWidth] = useState<number>(0);
    const [images, setImages] = useState<any[]>([]);
    const [memoImages, setMemoImages] = useState<any[]>([]);

    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [loadingMemoImages, setLoadingMemoImages] = useState<boolean>(false);
    const [memoImagesExist, setMemoImagesExist] = useState<boolean>(false);

    const [currentTab, setCurrentTab] = useState<string>('Questions');

    useEffect(() => {
        if (previewImage) {
            setLoadingImages(true);
            const imageLoadPromises = previewImage.image_urls.map((image_url: ImageUrl) => new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve({ url: image_url.url, width: img.width });
                img.onerror = reject;
                img.src = image_url.url;
            }));

            Promise.all(imageLoadPromises).then(imageData => {
                let maxWidthImage = 0;
                imageData.forEach(({ width }) => {
                    if (width > maxWidthImage) {
                        maxWidthImage = width;
                    }
                });

                setMaxWidth(maxWidthImage);
                setImages(imageData);
                setLoadingImages(false);
            });

            setLoadingMemoImages(true);
            setMemoImagesExist(false);
            const memoImageLoadPromises = previewImage.answer_image_urls.map((image_url: ImageUrl) => new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve({ url: image_url.url, width: img.width });
                img.onerror = reject;
                img.src = image_url.url;
            }));

            Promise.all(memoImageLoadPromises).then(memoImageData => {
                let maxWidthImage = 0;
                memoImageData.forEach(({ width }) => {
                    if (width > maxWidthImage) {
                        maxWidthImage = width;
                    }
                });

                setMaxMemoWidth(maxWidthImage);
                setMemoImages(memoImageData);

                if (memoImageData.length > 0) {
                    setMemoImagesExist(true);
                }
                setLoadingMemoImages(false);
            });
        }

    }, [previewImage])

    const tabItems: TabsProps['items'] = [
        {
            key: 'Questions',
            label: 'Questions',
            children: loadingImages ? <Spin /> : images.map(({ url, width }) => {
                const percentageWidth = (width / maxWidth) * 80;
                return (
                    <img
                        key={url}
                        src={url}
                        style={{
                            maxWidth: `${percentageWidth}%`,
                            height: 'auto',
                            display: 'block'
                        }}
                    />
                );
            })
        },
        {
            key: 'Memo',
            label: 'Memo',
            children: loadingMemoImages ? <Spin /> : !memoImagesExist ? <Text>No memo exists</Text> : memoImages.map(({ url, width }) => {
                const percentageWidth = (width / maxMemoWidth) * 80;
                return (
                    <img
                        key={url}
                        src={url}
                        style={{
                            left: 0,
                            maxWidth: `${percentageWidth}%`,
                            height: 'auto',
                            display: 'block'
                        }}
                    />
                );
            })
        },
        {
            key: 'Explanation',
            label: 'Explanation',
            children: previewImage?.explanation?.image_url ? (
                <img
                    src={previewImage.explanation.image_url}
                    style={{
                        maxWidth: '80%',
                        height: 'auto',
                        display: 'block',
                        margin: '0 auto'
                    }}
                    alt="Explanation"
                />
            ) : (
                <Text>No explanation image available</Text>
            )
        }
    ]


    return <>
        <Modal
            open={isModalOpen}
            width="50%"
            onCancel={() => {
                setIsModalOpen(false);
                setIsFlagFormOpen(false);
                setFlagReason('');
                setImages([]);
                setMemoImages([]);
            }}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
            footer={[
                <Button key="cancel" danger onClick={() => setIsModalOpen(false)} disabled={isFlagFormOpen}>
                    Cancel
                </Button>,
                previewImage && !previewImage.flagged && (
                    <Button
                        key="flag"
                        className="flag-button"
                        onClick={() => handleFlag(previewImage, 'flag')}
                        style={{ color: 'orange', borderColor: 'orange' }}
                    >
                        {isFlagFormOpen ? 'Submit Flag' : 'Flag'}
                    </Button>
                ),
                previewImage && previewImage.flagged && (
                    <Tooltip title={previewImage.flag_reason || ''}>
                        <Button
                            key="unflag"
                            className="flag-button"
                            onClick={() => handleFlag(previewImage, 'remove')}
                            style={{ color: 'orange', borderColor: 'orange' }}
                            disabled={isFlagFormOpen}
                        >
                            Remove flag
                        </Button>
                    </Tooltip>
                ),
                <Button key="ok" type="primary" onClick={handleAddOrRemove} disabled={isFlagFormOpen}>
                    {previewImage && selectedImages.some((item) => item.id === previewImage.id) ? 'Remove' : 'Add'}
                </Button>
            ]}
        >
            {isGeneratingPDF ? (
                <Spin />
            ) : generatedPDF ? (
                generatedPDF
            ) : (
                previewImage && <Tabs defaultActiveKey={currentTab} items={tabItems} onChange={(key: string) => setCurrentTab(key)} />
            )}

            {isFlagFormOpen && (
                <div style={{ marginTop: '16px', width: '80%' }}>
                    <Input
                        value={flagReason}
                        onChange={(e) => setFlagReason(e.target.value)}
                        placeholder="Enter a reason for flagging"
                    />
                </div>
            )}
        </Modal>
    </>

}

export const FinishModal = ({
    isFinishModalOpen,
    isFinalizing,
    form,
    setIsFinishModalOpen,
    handleFinishSubmit
}: FinishModalProps) => {
    return <>
        <Modal
            open={isFinishModalOpen}
            onCancel={() => setIsFinishModalOpen(false)}
            footer={[
                <Button key="cancel" onClick={() => setIsFinishModalOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleFinishSubmit} loading={isFinalizing}>
                    Submit
                </Button>
            ]}
        >
            <Spin spinning={isFinalizing}>
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a file name',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter file name"
                            maxLength={20}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    </>
}